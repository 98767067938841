import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import $ from 'jquery';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import DispachSummaryDashboard from '../amns/dispatchSummaryDashboard';
import DispatchSummaryDashboard from '../amns/daywisedispatchsummary';
import DelayDetentionReport from '../amns/detentionDelayReport';
import ZoneWiseDispachSummaryDashboard from "../amns/zonewiseDispatchSummary"
import TruckLoadSummary from '../amns/detentionTruckLoadSummary'
import CountUp from 'react-countup';

import {
    getHyphenDDMMMYYYYHHMM,
} from '../common/utils';
var redirectURL = require("../redirectURL")
var moment = require('moment')

var googleAnalytics = require("../common/googleanalytics");
const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        maxHeight: 200,  // Set max height
        overflowY: 'hidden',  // Add scrol
    }),
}
class DetentionReportComponent extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        defaultColDef: {
            sortable: true,
            filter: true,
            //editable: true,
            resizable: true
        },
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        show: false,
        basicType: "",
        basicTitle: "",
        loadshow: "show-n",
        overly: "show-n",
        transporters_options: [],
        customers_options: [],
        trucks_options: [],
        rowData: [],
        filteredData: [],
        showTrucksList: "show-n",
        from_date: "",
        to_date: "",
        transporter_code: [{ label: "ALL", value: "" }],
        customer_code: [{ label: "ALL", value: "" }],
        detentionreport_btn: "btn-success",
        tat_intransit_btn: "btn-default",
        tat_transit_time_btn: "btn-default",
        daywise_dispatch_btn: "btn-default",
        delay_report_btn: "btn-default",
        truck_loading_btn: "btn-default",
        transporter_zone_options: [],
        city_options: [],
        customer_city: [{ label: "ALL", value: "" }],
        transporter_zone: [{ label: "ALL", value: "" }],
        lessthanaday: [],
        greaterthanaday: [],
        abovetwodays: [],
        filterOptions: [{ label: "Reached Customer", value: "reached_customer" }, { label: "Invoice", value: "invoice" },],
        selectedFilter : { label: "Reached Customer", value: "reached_customer" },
        usergridstate:[],
        screenurl: "",
        screentitle: "",
        road_data_btn: 'btn-danger',
        rail_data_btn: 'btn-default',
        rowDataRail:[],
        is_interplant_enabled:false,
        selectedTruck : { label: "ALL", value: "" },  
        trucksOptions :[{ label: "ALL", value: "" }],
        filteredTrucks : [{ label: "ALL", value: "" }],    

    }

    componentDidMount() {

        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        })
        
        var plant_code = localStorage.getItem("pc")
        var params = {}
        // if (![undefined, null, "undefined", ""].includes(plant_code)) {
        //     params.plant_code = plant_code
        // }
        if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "undefined")
        {
            params.consigner_code = localStorage.getItem("pc")
        }
        redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
        .then(async (resp) => {
            await this.setState({
                usergridstate: resp.data
            });
            await this.restoreGridStates();
        });
        redirectURL.post("dashboard/getFiltersDataForDetentionReport", params).then(response => {

            var data = response.data

            var transporters_options = [{ label: "ALL", value: "" }]
            if (data.transporters_data.length > 0) {
                data.transporters_data.map(e => transporters_options.push(
                    {
                        label: e.transporter_name, value: e.transporter_code
                    }))
            }

            var customers_options = [{ label: "ALL", value: "" }]
            if (data.customers_data.length > 0) {
                data.customers_data.map(e => customers_options.push(
                    {
                        label: e.consignee_name + e.consignee_code, value: e.consignee_code
                    }))
            }
            var city_options = [{ label: "ALL", value: "" }]
            if (data.city_data.length > 0) {
                data.city_data.map(e => city_options.push(
                    {
                        label: e, value: e
                    }))
            }
            var transporter_zone_options = [{ label: "ALL", value: "" }]
            if (data.transportersZonesList.length > 0) {
                data.transportersZonesList.forEach(e => {
                    if (e) {
                        transporter_zone_options.push({
                            label: e, 
                            value: e
                        });
                    }
                });                
            }
            let {is_interplant_enabled =false } = response.data
            var trucks_options = [{ label: "ALL", value: "" }]
            if (data.trucks_data.length > 0) {
                data.trucks_data.map(e => trucks_options.push(
                    {
                        label: e, value: e
                    }))
            }
            console.log(trucks_options,"truck options")
            this.setState({
                transporters_options: transporters_options,
                customers_options: customers_options,
                trucks_options: data.trucks_data,
                city_options: city_options,
                transporter_zone_options: transporter_zone_options,
                is_interplant_enabled,
                trucksOptions:trucks_options || [],
            })

            var sdate = moment.parseZone(new Date()).subtract(10, "days").format("YYYY-MM-DD")
            var edate = moment.parseZone(new Date()).format("YYYY-MM-DD")

            this.setState({
                from_date: sdate,
                to_date: edate
            })

            var parameters = {
                from_date: sdate + " 00:00:00",
                to_date: edate + " 23:59:59"
            }
            this.onLoadData(parameters)

        })

    }


    onLoadData = (params = {}) => {
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        if (this.state.selectedFilter.value && this.state.detentionreport_btn == 'btn-success') {
            params.date_filter = this.state.selectedFilter.value
        }
        let PlantCode = localStorage.getItem("pc") || ""
        if (PlantCode && PlantCode != "undefined") {           
            params.consigner_code = Array.isArray(PlantCode) ? PlantCode : [PlantCode]
        }
        redirectURL.post("dashboard/getDetentionReportData", params).then(response => {
            try {
                var records = []
                if (Object.keys(response.data).length > 0) {
                    records = response.data.detention_data || [];
                    var lessthanaday = [];
                    var greaterthanaday = [];
                    var abovetwodays = [];
                    records.map((rec) => {
                        if (rec.detention_time_sec != undefined && rec.detention_time_sec != '') {
                            var detentionTime = (parseFloat(rec.detention_time_sec)) / 3600;
                            if (detentionTime <= 24) {
                                lessthanaday.push(rec);
                            }
                            else if (detentionTime > 24 && detentionTime <= 48) {
                                greaterthanaday.push(rec);
                            }
                            else {
                                abovetwodays.push(rec);
                            }
                        }
                    });
                }
                this.setState({
                    rowData: records,
                    lessthanaday: lessthanaday,
                    greaterthanaday: greaterthanaday,
                    abovetwodays: abovetwodays,
                    loadshow: "show-n",
                    overly: "show-n"
                })
            } catch (error) {
                console.log("Error 217:", error)
            }

        })
    }

    onClickFilterData = () => {
        if (this.state.from_date != "") {
            if (this.state.to_date != "") {
                if (new Date(this.state.from_date + " 00:00:00") < new Date(this.state.to_date + " 23:59:59")) {
                    var differenceMs = Math.abs(new Date(this.state.to_date) - new Date(this.state.from_date))
                    var differenceDays = differenceMs / (1000 * 60 * 60 * 24)
                    if (differenceDays <= 31) {
                        var params = {
                            from_date: this.state.from_date + " 00:00:00",
                            to_date: this.state.to_date + " 23:59:59",
                        }


                        var customer_codes = []
                        if (![undefined, null, "undefined", ""].includes(this.state.customer_code)) {
                            this.state.customer_code.map(e => {
                                if (e.value != "") {
                                    customer_codes.push(e.value)
                                }
                            })
                        }
                        var transporter_codes = []
                        if (![undefined, null, "undefined", ""].includes(this.state.transporter_code)) {
                            this.state.transporter_code.map(e => {
                                if (e.value != "") {
                                    transporter_codes.push(e.value)
                                }
                            })
                        }
                        var consignee_cities = []
                        if (![undefined, null, "undefined", ""].includes(this.state.customer_city)) {
                            this.state.customer_city.map(e => {
                                if (e.value != "") {
                                    consignee_cities.push(e.value)
                                }
                            })
                        }
                        var transporter_zones = []
                        if (![undefined, null, "undefined", ""].includes(this.state.transporter_zone)) {
                            this.state.transporter_zone.map(e => {
                                if (e.value != "") {
                                    transporter_zones.push(e.value)
                                }
                            })
                        }
                        var truck_no = $("#inputTruck").val();
                        if (truck_no != "") params.truck_no = truck_no
                        if(this.state.selectedTruck && this.state.selectedTruck.value) params.truck_no = this.state.selectedTruck.value
                        if (transporter_codes.length > 0) params.transporter_codes = JSON.stringify(transporter_codes)
                        if (customer_codes.length > 0) params.customer_codes = JSON.stringify(customer_codes)
                        if (transporter_zones.length > 0) params.transporter_zones = JSON.stringify(transporter_zones)
                        if (consignee_cities.length > 0) params.consignee_cities = JSON.stringify(consignee_cities)
                        if(this.state.rail_data_btn!='btn-danger'){
                            this.onLoadData(params)
                        }else{
                            this.onLoadRailData(params)
                        }


                    }
                    else {
                        this.setState({
                            show: true,
                            basicType: "danger",
                            basicTitle: "Date Range can't be greater than 1 month"
                        })
                    }
                } else {
                    this.setState({
                        show: true,
                        basicType: "danger",
                        basicTitle: "From Date Can't be Greater Than To Date"
                    })
                }
            } else {
                this.setState({
                    show: true,
                    basicType: "danger",
                    basicTitle: "Please fill To Date"
                })
            }
        } else {
            this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Please fill From Date"
            })
        }
    }

    onClickHideAll = () => {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
            showTrucksList: "show-n",
        })
    }

    // initalList = (event) => {
    //     $(".trucks-dropdown").removeClass("show-n");
    //     var dataset = this.state.trucksOptions;
    //     var tptunqrecords = dataset.slice(0, 100);
    //     this.setState({
    //         filteredData: tptunqrecords,
    //         showTrucksList: "show-m",
    //     })
    // }
    // handleBlur = () => {
    //     this.setState({
    //         showTrucksList: "show-n", // Hide dropdown when input loses focus
    //     });
    // }
    // handlerForm = (event) => {
    //     $(".trucks-dropdown").removeClass("show-n");
    //     var dataset = this.state.trucksOptions;
    //     var tptunqrecords = []
    //     if (dataset.length > 0) {
    //         dataset.filter(f =>
    //             tptunqrecords.push(f)
    //         );
    //     }
    //     else {
    //         tptunqrecords = dataset
    //     }
    //     // eslint-disable-next-line array-callback-return
    //     var filteredResult = tptunqrecords.filter((e) => {
    //         if (e != "" && e != null) {
    //             return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
    //         }

    //     });
    //     filteredResult = filteredResult.slice(0, 100);
    //     this.setState({
    //         filteredData: filteredResult,
    //         showTrucksList: "show-m",
    //     })
    // }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        const eventDate = moment(event._d);
        const formattedDate = eventDate.format('YYYY-MM-DD');
    
        if (!this.state.to_date) {
            this.setState({ from_date: formattedDate });
            return;
        }
        const endDate = moment(this.state.to_date);
        if (eventDate.isAfter(endDate)) {
            this.setState({
                from_date: "",
                show: true,
                basicType: "danger",
                basicTitle: "From Date should  be Less than To Date",
            });
        } else {
            this.setState({ from_date: formattedDate });
        }
    }

    handlerEndDateTime = (event, currentDate, selectedDate) => {
        const eventDate = moment(event._d);
        const formattedDate = eventDate.format('YYYY-MM-DD');
        if (!this.state.from_date) {
            this.setState({ to_date: formattedDate });
            return;
        }
        const startDate = moment(this.state.from_date);
        if (startDate.isAfter(eventDate)) {
            this.setState({
                to_date: "",
                show: true,
                basicType: "danger",
                basicTitle: "To Date should  be Greater than From Date",
            });
        } else {
            this.setState({ to_date: formattedDate });
        }
    }

    onClickTab = (tab) => {
        if (tab == "detentionreport") {
            this.setState({
                detentionreport_btn: "btn-success",
                tat_intransit_btn: "btn-default",
                tat_transit_time_btn: "btn-default",
                daywise_dispatch_btn: "btn-default",
                delay_report_btn: "btn-default",
                truck_loading_btn: "btn-default",
                from_date: "",
                to_date: "",
                transporter_code: [{ label: "ALL", value: "" }],
                customer_code: [{ label: "ALL", value: "" }],
            })
            this.onLoadData()
        }
        else if (tab == "intransit") {
            this.setState({
                detentionreport_btn: "btn-default",
                tat_intransit_btn: "btn-success",
                tat_transit_time_btn: "btn-default",
                daywise_dispatch_btn: "btn-default",
                delay_report_btn: "btn-default",
                truck_loading_btn: "btn-default",
            })
        }
        else if (tab == "transit_time") {
            this.setState({
                detentionreport_btn: "btn-default",
                tat_intransit_btn: "btn-default",
                tat_transit_time_btn: "btn-success",
                daywise_dispatch_btn: "btn-default",
                delay_report_btn: "btn-default",
                truck_loading_btn: "btn-default",
                from_date: "",
                to_date: "",
                transporter_code: [{ label: "ALL", value: "" }],
                customer_code: [{ label: "ALL", value: "" }],
            })
            this.onLoadData()
        } else if (tab == "daywise_dispatch") {
            this.setState({
                detentionreport_btn: "btn-default",
                tat_intransit_btn: "btn-default",
                tat_transit_time_btn: "btn-default",
                daywise_dispatch_btn: "btn-success",
                delay_report_btn: "btn-default",
                truck_loading_btn: "btn-default",
            });

        } else if (tab == "delay_report") {
            this.setState({
                detentionreport_btn: "btn-default",
                tat_intransit_btn: "btn-default",
                tat_transit_time_btn: "btn-default",
                daywise_dispatch_btn: "btn-default",
                delay_report_btn: "btn-success",
                truck_loading_btn: "btn-default",
            });

        } else if (tab == "truck_loading") {
            this.setState({
                detentionreport_btn: "btn-default",
                tat_intransit_btn: "btn-default",
                tat_transit_time_btn: "btn-default",
                daywise_dispatch_btn: "btn-default",
                delay_report_btn: "btn-default",
                truck_loading_btn: "btn-success",
            });
        }
        // Bug Fixes - Bug 5782 - Whatever truck number is selected in one tab, the same selected truck number is displaying in another tab in the Trucks field
        this.setState({selectedTruck:{ label: "ALL", value: "" }})
    }

    onClickCounter(data) {
        this.setState({
            rowData: this.state[data]
        });
    }
    initialList = () => {
        const dataset = this.state.trucksOptions;
        const tptunqrecords = dataset.slice(0, 100);  // Limit to 100 trucks
        this.setState({
            filteredTrucks: tptunqrecords,
            showTrucksList: 'show-m',  // Show the dropdown
        });
    };
    // Hide dropdown when the input loses focus
    handleBlur = () => {
        this.setState({
            showTrucksList: 'show-n',  // Hide dropdown
        });
    };
    // Handle user input in the dropdown and filter the trucks
    handleInputChange = (inputValue) => {
        const { trucksOptions } = this.state;
        // Filter trucks based on the input value
        let filteredResult = [];
        if (inputValue) {
            filteredResult = trucksOptions.filter((truck) =>
                truck.label.toLowerCase().includes(inputValue.toLowerCase())
            );
        } else {
            filteredResult = trucksOptions;  // Show all trucks if input is empty
        }
        filteredResult = filteredResult.slice(0, 100);  // Limit to 100 results
        this.setState({
            filteredTrucks: filteredResult,
            showTrucksList: 'show-m',  // Show the dropdown
        });
    };
    // Handle truck selection
    handleSelectChange = (selectedTruck) => {
        this.setState({ selectedTruck });
    };
    onShowFilters = () => {
        const {
            detentionreport_btn,
            filterOptions,
            selectedFilter,
            from_date,
            to_date,
            tat_transit_time_btn,
            customer_code,
            customers_options,
            transporter_code,
            transporters_options,
            transporter_zone,
            transporter_zone_options,
            selectedTruck,
            trucksOptions,
            filteredTrucks,
        } = this.state;    
        const inputProps = (placeholder, name,value) => ({
            placeholder,
            name,
            autoComplete: 'off',
            readOnly: 'true',
            style: { backgroundColor: "white" },
            value,
        });    
        const selectProps = (placeholder, value, options, onChange) => ({
            placeholder,
            closeMenuOnSelect: false,
            isMulti: true,
            value,
            onChange,
            className: "border-radius-0",
            style: { borderRadius: "0px" },
            options
        });    
        return (
            <div className="row">
                <div className="col-xl-2 col-lg-2 form-group">
                    <label>Trucks</label>
                    <Select
                        className="border-radius-0"
                        isMulti={false}
                        id="selectedTruck"
                        options={filteredTrucks}  // Use filtered data for options
                        onChange={this.handleSelectChange}  // Handle truck selection
                        value={selectedTruck}  // Show selected truck
                        onInputChange={this.handleInputChange}  // Filter trucks as user types
                        onFocus={this.initialList}  // Load initial truck list when focused
                        onBlur={this.handleBlur}  // Hide dropdown on blur
                        required
                        styles={customStyles}  // Apply custom styles
                        placeholder="Search trucks..."
                />                  
                    {/* <input
                        type="text"
                        name="truck_no"
                        id="inputTruck"
                        placeholder="Trucks"
                        autoComplete="off"
                        className="form-control"
                        onFocus={this.initalList}
                        onChange={this.handlerForm}
                        onBlur={this.handleBlur} // Optionally use onBlur to hide dropdown
                    /> */}
                </div>
                {detentionreport_btn === 'btn-success' && (
                    <div className="col-xl-2 col-lg-2 form-group">
                        <label>Date Filter</label>
                        <Select
                            className="border-radius-0"
                            isMulti={false}
                            id="selectedFilter"
                            style={{ borderRadius: "0px" }}
                            options={filterOptions}
                            onChange={e => this.setState({ selectedFilter: e })}
                            value={selectedFilter}
                            required />
                    </div>
                )}
                <div className="col-xl-2 col-lg-2 form-group">
                    <label>From Date</label>
                    <Datetime
                        disableCloseOnClickOutside={false}
                        closeOnSelect={true}
                        inputProps={inputProps(tat_transit_time_btn === "btn-success" ? "Reached Customer" : "From Date", 'from_date',from_date)}
                        dateFormat="YYYY-MM-DD"
                        name="from_date"
                        onChange={this.handlerStartDateTime} 
                        />
                </div>
                <div className="col-xl-2 col-lg-2 form-group">
                    <label>To Date</label>
                    <Datetime
                        disableCloseOnClickOutside={false}
                        closeOnSelect={true}
                        inputProps={inputProps(tat_transit_time_btn === "btn-success" ? "Reached Customer" : "To Date", 'to_date',to_date)}
                        dateFormat="YYYY-MM-DD"
                        onChange={this.handlerEndDateTime} 
                        />
                </div>
                <div className="col-xl-2 col-lg-2 form-group">
                    <label>Customer</label>
                    <Select
                        {...selectProps("Select Customer", customer_code, customers_options, e => this.setState({ customer_code: e }))}
                    />
                </div>
                <div className="col-xl-2 col-lg-2 form-group">
                    <label>Transporter</label>
                    <Select
                        {...selectProps("Select Transporter", transporter_code, transporters_options, e => this.setState({ transporter_code: e }))}
                    />
                </div>
                <div className="col-xl-2 col-lg-2 form-group">
                    <label>Customer City</label>
                    <Select
                        {...selectProps("Select Transporter", transporter_zone, transporter_zone_options, e => this.setState({ transporter_zone: e }))}
                    />
                </div>
                <div className="col-xl-2 col-lg-2 form-group">
                    <button type="button" onClick={this.onClickFilterData} className="btn btn-success mt-30p">Get Data</button>
                </div>
            </div>
        );
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            // console.log("node here ",node)
            node.setSelected(true);
         
        });
        
	};

    onClickSaveGridState () {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "activetrips",
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		try {
			window.colState = this.gridColumnApi.getColumnState();
			window.groupState = this.gridColumnApi.getColumnGroupState();
			window.sortState = this.gridApi.getSortModel();
			window.filterState = this.gridApi.getFilterModel();

			let screenpage = 'detention report';

			let reqparams = {
				gridcolumns: window.colState,
				gridgroup: window.groupState,
				gridcolsort: window.sortState,
				gridcolfilter: window.filterState,
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: "detention report"
			}
			//console.log("reqparams ", reqparams)
			redirectURL.post("/consignments/saveGridStates", reqparams)
				.then((response) => {
					//console.log("State response ",response.data)
					this.setState({
						show: true,
						basicTitle: "Successfully saved grid layout",
						basicType: "success",
						screenurl: window.location.pathname,
						screentitle: screenpage
					})
				})
				.catch(function (e) {
					console.log("Error ", e)
				})
		}
		catch (e) { }
	}
    restoreGridStates (){
		if (this.state.usergridstate.length !== 0) {
			try {
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

			}
			catch (e) {

			}
		}
	}

    resetState = () => {

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		try {

			this.gridColumnApi.resetColumnState();
			this.gridColumnApi.resetColumnGroupState();
			this.gridApi.setSortModel(null);
			this.gridApi.setFilterModel(null);
			//console.log('column state reset');
			var reqparams = {
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: this.state.screenpage
			}

			redirectURL.post("/consignments/removeUserGridState", reqparams)
				.then((response) => {
					this.setState({
						show: true,
						basicTitle: "Successfully reset default grid layout",
						basicType: "success"
					})
				})
		}
		catch (e) { }

	};
    onGridState = () =>{
        console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
          
   }
    onClickTatTab = async (tab) => {
        if (tab == 1) {
            this.setState({
                road_data_btn: 'btn-danger',
                rail_data_btn: 'btn-default',
            })
        } else {
            this.setState({
                road_data_btn: 'btn-default',
                rail_data_btn: 'btn-danger',

            }, () => {
                if (this.state.rowDataRail.length == 0) {
                    this.onLoadRailData()
                }
            })
        }

    }
    onLoadRailData = async (params = {}) => {
        try {
            this.setState({
                loadshow: "show-m",
                overly: "show-m"
            });

            // Ensure from_date and to_date are set in params
            if (!params.from_date) {
                params.from_date = this.state.from_date
                    ? this.state.from_date + " 00:00:00"
                    : moment.parseZone(new Date()).subtract(10, "days").format("YYYY-MM-DD") + " 00:00:00";
            }
            if (!params.to_date) {
                params.to_date = this.state.to_date
                    ? this.state.to_date + " 23:59:59"
                    : moment.parseZone(new Date()).format("YYYY-MM-DD") + " 23:59:59";
            }
            let PlantCode = localStorage.getItem("pc") || ""
            if (PlantCode && PlantCode != "undefined") {
                params.consigner_code = PlantCode
            }
            const response = await redirectURL.post("dashboard/getTatTransitRailData", params);
            console.log(response,"response771")
            let records = Array.isArray(response.data)? response.data :  [];


            this.setState({
                rowDataRail: records,
                loadshow: "show-n",
                overly: "show-n"
            });
        } catch (error) {
            console.log("Error 282:", error);
        }
    };

    render() {
        const plant_code = localStorage.getItem("pc") || ""

        // console.log(this.state.detentionreport_btn , "detentionreport_btn")
        const rowDataTransitTime = this.state.rail_data_btn=='btn-danger'?this.state.rowDataRail : this.state.rowData

        var columnwithDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Type Of Tracking",
                field: "type_of_tracking",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },

            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 200,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    if (params.data.transporter_name != undefined && params.data.transporter_name != '') {
                        return params.data.transporter_name
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Shipment No",
                field: "shipment_id",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Parking Id",
                field: "parking_id",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Plant Code",
                field: "consigner_code",
                width: 120,
                filter: true,
                hide: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Plant Exit Time",
                field: "loading_yard_exit_time",
                width: 150,
                resizable: true,
                filter: true,
                valueGetter: function (params) {
                    if (params.data.loading_yard_exit_time != undefined && params.data.loading_yard_exit_time != "undefined" && params.data.loading_yard_exit_time != "null" && params.data.loading_yard_exit_time != null) {
                        if (params.data.loading_yard_exit_time.includes(".") == true) {
                            return getHyphenDDMMMYYYYHHMM(params.data.loading_yard_exit_time.split(".")[0]);
                        }
                        else {
                            return getHyphenDDMMMYYYYHHMM(params.data.loading_yard_exit_time);
                        }
                    }
                }
            },
            {
                headerName: "Invoice Date",
                field: "invoice_time",
                width: 150,
                resizable: true,
                filter: true,
                valueGetter: function (params) {
                    if (params.data.invoice_time != undefined && params.data.invoice_time != '') {
                        return getHyphenDDMMMYYYYHHMM(params.data.invoice_time)
                    }
                    else {
                        return "N/A"
                    }
                }
            },
            {
                headerName: "Shipment Time",
                field: "shipment_time",
                width: 150,
                resizable: true,
                filter: true,
                valueGetter: function (params) {
                    if (params.data.shipment_time != undefined && params.data.shipment_time != '') {
                        return getHyphenDDMMMYYYYHHMM(params.data.shipment_time)
                    }
                    else {
                        return "N/A"
                    }
                }

            },
            {
                headerName: "Batch No",
                field: "consignment_code",
                width: 150,
                hide: true,
                resizable: true,
                filter: true,
            },

            {
                headerName: "Customer Name",
                field: "consignee_name",
                width: 200,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Customer Code",
                field: "consignee_code",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },
            // {
            //     headerName: "Customer City Old",
            //     field: "consignee_city",
            //     width: 120,
            //     filter: true,
            //     resizable: true,
            //     sortable: true,
            //     hide:true,
            // },
            {
                headerName: "Customer City",
                field: "transporter_zone",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Route Id",
                field: "consignee_route_id",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Customer State",
                field: "consignee_state",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Reached Customer Vicinity",
                field: "recent_dealer_reported",
                width: 150,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: params =>{
                    if (params.data.recent_dealer_reported != undefined && params.data.recent_dealer_reported != '') {
                       
                        const formattedDateTime = params.data.recent_dealer_reported.split(".")[0];
                        return getHyphenDDMMMYYYYHHMM(formattedDateTime)
                    }
                }
            },
            {
                headerName: "Left Customer Vicinity",
                field: "left_dealer_marked",
                width: 150,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    if (params.data.left_dealer_marked != undefined && params.data.left_dealer_marked != '') {
                        const formattedDateTime = params.data.left_dealer_marked.split(".")[0];
                        return getHyphenDDMMMYYYYHHMM(formattedDateTime)
                       
                    }
                }
                
            },
            {
                headerName: "Detention Time (HH:MM)",
                field: "detention_time_sec",
                width: 140,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.detention_time_sec != undefined && params.data.detention_time_sec != '') {
                            return secondsToHM(parseFloat(params.data.detention_time_sec))
                        }
                    }
                    catch (e) {
                        return "";
                    }
                }
            },
            
            {
                headerName: "Detention Time (Hours)",
                field: "detention_time_sec",
                width: 140,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.detention_time_sec != undefined && params.data.detention_time_sec != '') {
                            return convertSecondsToHours(parseFloat(params.data.detention_time_sec))
                        }
                    }
                    catch (e) {
                        return "";
                    }
                }
            },
            {
                headerName: "Detention Time (Days)",
                field: "detention_time_sec",
                width: 140,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.detention_time_sec != undefined && params.data.detention_time_sec != '') {
                            return secondsToDays(parseFloat(params.data.detention_time_sec))
                        }
                    }
                    catch (e) {
                        return "";
                    }
                }
            },
            { 
                headerName: "Item Quantity (in Tons)",
                field: "item_quantity",
                width: 140,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: (params) => {
                    try {
                        const { item_quantity = 0 } = params.data
                        return parseFloat(item_quantity)
                    } catch (e) {
                        console.log("Error :", e)
                    }
                }
            },
            // { Bug Id: 3504 ,
            //     headerName: "Driver Mobile No",
            //     field: "driver_mobile_no",
            //     width: 140,
            //     filter: true,
            //     resizable: true,
            //     sortable: true,
            // },


        ]

        var columnwithDefsforTatTransitTime = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Type Of Tracking",
                field: "type_of_tracking",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
                hide:this.state.road_data_btn=='btn-danger'?false:true,
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 200,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    if (params.data.transporter_name != undefined && params.data.transporter_name != '') {
                        return params.data.transporter_name
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Shipment No",
                field: "shipment_id",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Parking Id",
                field: "parking_id",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Plant Code",
                field: "consigner_code",
                width: 120,
                filter: true,
                hide: true,
                resizable: true,
                sortable: true,
            },


            {
                headerName: "Invoice Time",
                field: "invoice_time",
                width: 150,
                resizable: true,
                filter: true,
                valueGetter: function (params) {
                    if (params.data.invoice_time != undefined && params.data.invoice_time != '') {
                        return getHyphenDDMMMYYYYHHMM(params.data.invoice_time)
                    }
                    else {
                        return "N/A"
                    }
                }

            },
            {
                headerName: "Shipment Time",
                field: "shipment_time",
                width: 150,
                resizable: true,
                filter: true,
                valueGetter: function (params) {
                    if (params.data.shipment_time != undefined && params.data.shipment_time != '') {
                        return getHyphenDDMMMYYYYHHMM(params.data.shipment_time)
                    }
                    else {
                        return "N/A"
                    }
                }

            },
            {
                headerName: "Batch No",
                field: "consignment_code",
                width: 150,
                resizable: true,
                filter: true,
            },

            {
                headerName: "Customer Name",
                field: "consignee_name",
                width: 200,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Customer Code",
                field: "consignee_code",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Customer City",
                field: "transporter_zone",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Customer State",
                field: "consignee_state",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Route Id",
                field: "consignee_route_id",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
                hide:this.state.road_data_btn=='btn-danger'?false:true,
            },
            {
                headerName: "Reached Customer Vicinity",
                field: "recent_dealer_reported",
                width: 150,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    if (params.data.recent_dealer_reported != undefined && params.data.recent_dealer_reported != '') {
                        return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported)
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            {
                headerName: "Plant Exit Time",
                field: "loading_yard_exit_time",
                width: 150,
                resizable: true,
                filter: true,
                valueGetter: function (params) {
                    if (params.data.loading_yard_exit_time != undefined && params.data.loading_yard_exit_time != '') {
                        return getHyphenDDMMMYYYYHHMM(params.data.loading_yard_exit_time)
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            {
                headerName: "Standard Transit Time (In Days)",
                field: "transit_time",
                width: 150,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Actual Transit Time (In Days)",
                field: "transit_time",
                width: 150,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    // if (params.data.recent_dealer_reported != undefined && params.data.recent_dealer_reported != '' && params.data.loading_yard_exit_time != undefined && params.data.loading_yard_exit_time != '') {
                    //     var loading_yard_exit_time = params.data.loading_yard_exit_time;
                    //     var recent_dealer_reported = params.data.recent_dealer_reported;
                    //     if(params.data.recent_dealer_reported.includes(".") == true)
                    //     {
                    //         recent_dealer_reported = recent_dealer_reported.split(".")[0];
                    //     }
                    //     if(params.data.loading_yard_exit_time.includes(".") == true)
                    //     {
                    //         loading_yard_exit_time = loading_yard_exit_time.split(".")[0];
                    //     }
                    //     const fromDate = moment(loading_yard_exit_time, "YYYY-MM-DD HH:mm:ss");
                    //     const toDate = moment(recent_dealer_reported, "YYYY-MM-DD HH:mm:ss");  
                    //     const differenceInMinutes = toDate.diff(fromDate, 'minutes');                   
                    //     const differenceInDays =  differenceInMinutes / (24 * 60);
                    //     if(differenceInDays.toString().includes(".") == true)
                    //     {              
                    //         return differenceInDays.toFixed(2);
                    //     }
                    //     else
                    //     {
                    //         return differenceInDays;
                    //     }
                    // }
                    var ste = params.data.loading_yard_exit_time;
                    var stsec = moment.parseZone(ste).format("x");
                    if (params.data.recent_dealer_reported != "" && params.data.recent_dealer_reported != undefined) {
                        if (params.data.loading_yard_exit_time < params.data.recent_dealer_reported) {
                            var etsec = moment.parseZone(params.data.recent_dealer_reported).format("x")
                            var diff = (parseInt(etsec) - parseInt(stsec)) / 1000;
                            // console.log(diff,"line 2047")
                            var timespent = secondsToDhms(diff);
                            return timespent;
                        }
                        else {
                            return "0 Days 0 Hrs 0 Mins";
                        }

                    }
                    else {
                        return "Not Available";
                    }
                }
            },
            {
                headerName: "Left Customer Vicinity",
                field: "left_dealer_marked",
                width: 150,
                filter: true,
                hide: true,
                resizable: true,
                sortable: true,
                // valueGetter: function (params) {
                //     if (params.data.left_dealer_marked != undefined && params.data.left_dealer_marked != '') {
                //         return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked)
                //     }
                //     else {
                //         return "N/A";
                //     }
                // }
            },


        ]
        var railColumns = [
            {
                headerName: "First Mile",
                field: "first_mile",
                width: 150,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    const { first_mile } = params.data;
                
                    if (first_mile) {
                        const stsec = parseTimeString(first_mile); // Get Unix timestamp in seconds
                        return secondsToDhms(stsec); // Convert to desired format
                    } else {
                        return "Not Available";
                    }
                }
                
            },
            {
                headerName: "Middle Mile",
                field: "middle_mile",
                width: 150,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    const { middle_mile } = params.data;
                
                    if (middle_mile) {
                        const stsec = parseTimeString(middle_mile); // Get Unix timestamp in seconds
                        return secondsToDhms(stsec); // Convert to desired format
                    } else {
                        return "Not Available";
                    }
                }
            },
            {
                headerName: "Last Mile",
                field: "last_mile ",
                width: 150,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    const { last_mile } = params.data;
                
                    if (last_mile) {
                        const stsec = parseTimeString(last_mile); // Get Unix timestamp in seconds
                        return secondsToDhms(stsec); // Convert to desired format
                    } else {
                        return "Not Available";
                    }
                }
            },
            {
                headerName: "Total Mile",
                field: "total_mile",
                width: 150,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    const { total_mile } = params.data;
                
                    if (total_mile) {
                        const stsec = parseTimeString(total_mile); // Get Unix timestamp in seconds
                        return secondsToDhms(stsec); // Convert to desired format
                    } else {
                        return "Not Available";
                    }
                }
            },
            {
                headerName:"Last Mile LR No",
                field:"last_mile_lr_no",
                width:140,
            },

        ]
        if(this.state.rail_data_btn=='btn-danger'){
            var columnwithDefsforTatTransitTime = [...columnwithDefsforTatTransitTime,...railColumns]
        }

        var listData = []
        // eslint-disable-next-line array-callback-return
        // this.state.filteredData.map(function (e) {
        //     listData.push(<li className="dropdown-truck-no">{e}</li>)
        // })

        let {is_interplant_enabled} = this.state
        if(!localStorage.getItem("pc") || localStorage.getItem("pc")&&localStorage.getItem("pc").toLocaleLowerCase()=="all"){
            is_interplant_enabled = true
        }
        return (
            <div className='container-fluid'>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={() => (this.setState({ show: false }))}
                >
                </SweetAlert>
                <div className='col-12' style={{ marginLeft: "-15px" }}>
                    
                    {(plant_code && plant_code.toLocaleLowerCase() != "pune_s") &&
                        (
                            <>
                                <button className={'btn ' + this.state.detentionreport_btn} onClick={this.onClickTab.bind(this, "detentionreport")}>Detention Report</button>
                                {(is_interplant_enabled) &&
                                    <button className={'btn ' + this.state.tat_intransit_btn} onClick={this.onClickTab.bind(this, "intransit")}>TAT - Inter Plant </button>
                                }
                                <button className={'btn ' + this.state.tat_transit_time_btn} onClick={this.onClickTab.bind(this, "transit_time")}>TAT - Transit Time</button>
                                <button className={'btn ' + this.state.daywise_dispatch_btn} onClick={this.onClickTab.bind(this, "daywise_dispatch")}> Dispatch Summary</button>
                                <button className={'btn ' + this.state.truck_loading_btn} onClick={this.onClickTab.bind(this, "truck_loading")}>Zonewise Dispatch Report</button>
                                <button className={'btn ' + this.state.delay_report_btn} onClick={this.onClickTab.bind(this, "delay_report")}>Delay Report</button>
                            </>
                        )
                    }

                </div>
                {this.state.tat_intransit_btn == "btn-success" && <DispachSummaryDashboard />}
                {this.state.detentionreport_btn == "btn-success" &&
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <i class="icofont icofont-vehicle-delivery-van cus-i"></i>
                                {"Detention Report"}
                            </h5>
                        </div>
                        <div className={"card-body col-xl-12 col-lg-12"}>
                            {/* <div className={"trucks-dropdown " + this.state.showTrucksList} style={{ top: "94px" }}>
                                <ul>
                                    {listData}
                                </ul>
                            </div> */}
                            {this.onShowFilters()}
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="crm-numbers pb-0">
                                                <div className="row">

                                                    <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "lessthanaday")}>
                                                        <span className="f14"> 0 - 24 Hrs</span>
                                                        <h4 className="darkgreen f50"><span className="counter"><CountUp end={(this.state.lessthanaday) ? this.state.lessthanaday.length : 0} /></span></h4>

                                                    </div>

                                                    <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "greaterthanaday")}>
                                                        <span className="f14">24 - 48 Hrs</span>
                                                        <h4 className="maroon f50"><span className="counter"><CountUp end={(this.state.greaterthanaday) ? this.state.greaterthanaday.length : 0} /></span></h4>
                                                    </div>

                                                    <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "abovetwodays")}>
                                                        <span className="f14">Above 48 Hrs</span>
                                                        <h4 className="txt-danger f50"><span className="counter"><CountUp end={(this.state.abovetwodays) ? this.state.abovetwodays.length : 0} /></span></h4>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="card-header">
                                <div style={{display:'flex',flexDirection:'row-reverse'}}>
                                    <span ></span>
                                     <span className="" >

                                        <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                            <i className="icofont icofont-save"></i> Save Grid Layout
                                        </button>
                                        <button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
                                            <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <div id="myGrid" style={{ width: "100%", height: "100vh" }} className={"ag-theme-balham"}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={1000}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this },
                                    }}
                                    enableRangeSelection={true}
                                />
                            </div>

                        </div>

                    </div>
                }
                {this.state.tat_transit_time_btn == "btn-success" && (<>
                    
                    {
                        (!localStorage.getItem("pc") ||localStorage.getItem("pc").toLocaleLowerCase() =="all"   || localStorage.getItem("pc")=="HZ") &&    
                        <div className={'col-xl-12 col-lg-12'} style={{marginTop:'12px'}}>
                        <button className={'btn ' + this.state.road_data_btn} style={{ marginLeft: "-15px" }}
                            onClick={this.onClickTatTab.bind(this, 1)}
                        >Road</button>
                        <button className={'btn ' + this.state.rail_data_btn}
                            onClick={this.onClickTatTab.bind(this, 2)}
                        >Rail </button>
                    </div>
                    }
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <i class="icofont icofont-vehicle-delivery-van cus-i"></i>
                                {"TAT Transit Time"}
                            </h5>
                        </div>
                        <div className={"card-body col-xl-12 col-lg-12"}>
                            {/* <div className={"trucks-dropdown " + this.state.showTrucksList} style={{ top: "94px" }}>
                                <ul>
                                    {listData}
                                </ul>
                            </div> */}
                            {this.onShowFilters()}
                            <div id="myGrid" style={{ width: "100%", height: "100vh" }} className={"ag-theme-balham"}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefsforTatTransitTime}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={rowDataTransitTime}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={1000}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this },
                                    }}
                                    enableRangeSelection={true}
                                />
                            </div>

                        </div>

                    </div>
                    </>
                )
                }
                {this.state.daywise_dispatch_btn == "btn-success" && <DispatchSummaryDashboard />}
                {
                    this.state.delay_report_btn == "btn-success" && <DelayDetentionReport />
                }
                {
                    this.state.truck_loading_btn == "btn-success" && (
                        <TruckLoadSummary />
                    )
                }
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

            </div>
        )
    }
}

export default DetentionReportComponent;

function secondsToHM(seconds) {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600*24));
    // var h = Math.floor(seconds % (3600*24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);

    // var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    // var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    // var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return dDisplay + hDisplay + mDisplay;

    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh * 3600));
    var mm = Math.floor(hrr / 60)
    if (mm < 10) {
        var mins = "0" + mm;
    }
    else {
        var mins = mm;
    }

    if (hh < 10) {
        var hrs = "0" + hh;
    }
    else {
        var hrs = hh;
    }
    return hrs + ":" + mins;
}
function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
function secondsToDays(seconds) {
    // Calculate total days
    const days = seconds / (24 * 60 * 60); // Total seconds in a day = 24 hours * 60 minutes * 60 seconds

    // Remove trailing zeros and unnecessary decimal point
    const formattedDays = days.toFixed(2).replace(/\.?0+$/, '');

    return formattedDays;
}
function convertSecondsToHours(seconds) {
    // Convert seconds to hours
    const hours = seconds / 3600;

    // Format the hours
    const formattedHours = hours.toFixed(2).replace(/\.?0+$/, '');

    // Return the formatted hours
    return formattedHours;
}
function parseTimeString(timeString) {
    // Regular expression to match the pattern '0 days 08:36:44'
    const timePattern = /(\d+\s*days?)\s*(\d{2}:\d{2}:\d{2})/;
    // Execute the regex on the input string
    const match = timePattern.exec(timeString);
    if (!match) {
        console.warn("Time pattern not found in string:", timeString);
        return 0; // Return 0 if the pattern is not found
    }
    // Extract the matched groups
    const daysPart = match[1];
    const timePart = match[2];
    // Convert the days part to seconds
    const daysMatch = /(\d+)\s*days?/.exec(daysPart);
    const daysInSeconds = daysMatch ? parseInt(daysMatch[1]) * 86400 : 0;
    // Convert the time part to seconds
    const timeParts = timePart.split(':').map(Number);
    const hoursInSeconds = timeParts[0] * 3600;
    const minutesInSeconds = timeParts[1] * 60;
    const seconds = timeParts[2];
    const timeInSeconds = hoursInSeconds + minutesInSeconds + seconds;
    // Calculate the total seconds
    const totalSeconds = daysInSeconds + timeInSeconds;
    // console.log("Days Part:", daysPart); // '0 days'
    // console.log("Time Part:", timePart); // '08:36:44'
    // console.log("Total Seconds:", totalSeconds);
    return totalSeconds;
}




