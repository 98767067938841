/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
// import UpdateButton from "./updatelmstates";
// import UpdateTransitButton from "./updatetransitbutton";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
// import ShowTPTGpsMap from "../tptlogin/showtptgpsmap";
import DrawMap from '../common/drawmap';
import SimTrackingDashboardCharts from "./simTrackingCharts"
import SimTrackingDashboardAPICharts from "./simTrackingApiCharts"
import CountUp from 'react-countup';
import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYY } from '../common/utils'; 
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var alertIcon_50 = require("../../assets/icons/accident alert_16.jpeg");
var redirectURL = require('../redirectURL');
var moment = require('moment');
var CELL_DIMENSION_SIZE = 90;
var googleAnalytics = require("../common/googleanalytics");
var axios = require('axios');


export default class SimTrackingDashboard extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "SIM Tracking Consents",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			truckList: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			cluster_centroids: [],
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			frameworkComponents : {
				// showtptgpsmap:ShowTPTGpsMap,
                // UpdateButton:UpdateButton,
                // UpdateTransitButton:UpdateTransitButton,
            },
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
		
			showPlant:'show-n',
			showAddr:'show-n',
			request_type:'',
			view_type:'',
			tabplants:[],
			clickabletab:'',
			plantlist:[],
			stateFormData : "",
			sliderStateEmailsTranslate : "",
			overly : "show-n",
            rakeslist : [],
            loadingpoints:[],
            destinationpoints:[],
            userType:"",
            pinnedRegionBottomRowData:[],
            pinnedTransitBottomRowData:[],
            stockData:[],
            totalStock:0,
            totalSales:0,
            totalDispatches:0,
            railShare:0,
            totalFySales:0,
            totalFyDispatches:0,
            railFyShare:0,
            pendingForDispatchStock:0,
            transitStock:0,
            unloadingUpdatedDate:"",
            intransitUpdatedDate:"",
            screenView: "",
            todayInstalled: [],
            totalInstalled: [],
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			filteredData: [],
			truckNoList:[],
			showTrucksList : "show-n",
			startDate:'',
			endDate:'',
			truckNos:[],
			mapinfo:[],
            // duration:"",
			sliderRouteTranslate:'',
			tpttrucks:[],
            truck_no:[],
            tpt_code:[],
            truck_no_option:[],
            tpt_code_option:[],
            lastElement:"",
            lastNumbers:0,
            allData:[],
            page_no:0,
            alrData:0,
            total_count:[],
            activated:[],
            consentApproved:[],
            responsePending:[],
            allrowData:[],
            updated_on:"",
            networkType:{label:"All",value:"All"},
            updatedjiotime:"",
            updatedtelenitytime:"",
            // from_date: moment.parseZone().subtract(5,'days').format("YYYY-MM-DD"),
            // to_date: moment.parseZone().format("YYYY-MM-DD"),
            applications_using_sim_tracking:[],
            total_allocated_licenses:0,
            jio_allocated_licenses:0,
            telenity_allocated_licenses:0,
            total_available_licenses:0,
            jio_available_licenses:0,
            telenity_available_licenses:0,
            total_used_licenses:0,
            jio_used_licenses:0,
            telenity_used_licenses:0,
            reg_data_count:0,
            reg_pending_data_count:0,
            invalid_reg_data_count:0,
            act_data_count:0,
            act_pending_data_count:0,
            invalid_act_data_count:0,
            deact_data_count:0,
            deact_pending_data_count:0,
            invalid_deact_data_count:0,
            mobile_nos_reg_in_jio_n_telenity:0,
            mobile_nos_reg_in_jio:0,
            mobile_nos_reg_in_telenity:0,
            telenity_live_data_count:0,
            jio_and_telenity_overall_data:0,
            jio_live_data:0,
            telenity_overall_data:0,
            telenity_live_data:[],
            act_pending_data:0,
            invalid_act_data:0,
            deact_pending_data:[],
            invalid_deact_data:0,
            invalid_reg_data:0,
            reg_pending_data:0,
            columnWithDefs:[],
            deact_overall_data_columns:[],
            deact_overall_data:[],
            act_overall_data:[],
            act_overall_data_columns:[],
            reg_overall_data:[],
            reg_overall_data_columns:[],
            jio_consent_used_licenses:[],
            overall_used_licenses:[],
            overall_used_licenses_columns:[],
            telenity_used_licenses_data_columns:[],
            telenity_used_licenses_data:[],
            telenity_live_data_columns:[],
            headerName:"Registered Numbers",
            app_hit_count_data:[],
            aggrid_or_charts: true,
            prevHeadername:'',
            ivrcolumns:[],
            ivrrecords:[]
		};
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
        
        
    }

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
        this.setState({
            loadshow:"show-m",
        overly:"show-m"
        })
        var params = {
            
        }
        const getConsentApprovedUsedLicense = (records) => {
            var consent_result = []
            records.jio_live_data.map((item)=>{
                if(item.consentStatus == "approved" || item.consentStatus == "license_hold"){
                    console.log(item.consentStatus == "consent_approved")
                    consent_result.push(item)
                }
            })
            console.log(consent_result.length)
            return consent_result
        }

        redirectURL.post("/amns/getSimTrackingDashboardData", params)
            .then(response => {
                var records = response.data
                var records = records.replace(/NaN/g, 'null')
                console.log(JSON.parse(records))
                var records = JSON.parse(records)
                console.log(records.jio_and_telenity_overall_data , 'jio_and_telenity_overall_data')

                this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                    app_hit_count_data:records.app_hit_count_data,
                    applications_using_sim_tracking:records.applications_using_sim_tracking,
                    total_allocated_licenses: records.total_allocated_licenses,
                    jio_allocated_licenses: records.jio_allocated_licenses,
                    telenity_allocated_licenses: records.telenity_allocated_licenses,
                    total_available_licenses: records.total_available_licenses,
                    jio_available_licenses: records.jio_available_licenses,
                    telenity_available_licenses: records.telenity_available_licenses,
                    total_used_licenses: records.total_used_licenses,
                    jio_used_licenses: records.jio_used_licenses,
                    telenity_used_licenses: records.telenity_used_licenses,
                    reg_data_count:records.reg_data_count,
                    reg_pending_data_count:records.reg_pending_data_count,
                    invalid_reg_data_count:records.invalid_reg_data_count,
                    act_data_count:records.act_data_count,
                    act_pending_data_count:records.act_pending_data_count,
                    invalid_act_data_count:records.invalid_act_data_count,
                    deact_data_count:records.deact_data_count,
                    deact_pending_data_count:records.deact_pending_data_count,
                    invalid_deact_data_count:records.invalid_deact_data_count,
                    mobile_nos_reg_in_jio_n_telenity:records.mobile_nos_reg_in_jio_n_telenity,
                    mobile_nos_reg_in_jio:records.mobile_nos_reg_in_jio,
                    mobile_nos_reg_in_telenity:records.mobile_nos_reg_in_telenity,
                    telenity_live_data_count:records.telenity_live_data_count,
                    columnWithDefs:[
                        {
                            headerName: "Mobile No",
                            field: "mobile_no",
                            width: 120,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Consent Status",
                            field: "consentStatus",            
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        // {
                        //     headerName: "Network Type",
                        //     field: "networkType",
                        //     width: 120,
                        //     filter: true,
                        //     resizable: true,
                        //     sortable:true,
                        // },
                        {
                            headerName: "Service Provider",
                            field: "service_provider_name",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Source From",
                            field: "last_org_name",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        }, 
                        {
                            headerName: "Latitude",
                            field: "latitude",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Longitude",
                            field: "longitude",            
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Server Time",
                            field: "serverTime",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Registered Date",
                            field: "registeredDate",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.registeredDate !=undefined && params.data.registeredDate !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.registeredDate);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Approved Date",
                            field: "approvedDate",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.approvedDate !=undefined && params.data.approvedDate !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.approvedDate);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        
                        {
                            headerName: "Created Date",
                            field: "created_date_1",
                            width: 120,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.created_date_1 !=undefined && params.data.created_date_1 !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.created_date_1);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Consent Created On",
                            field: "consentCreatedOn",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.consentCreatedOn !=undefined && params.data.consentCreatedOn !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.consentCreatedOn);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Accuracy",
                            field: "accuracy",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },   
                        {
                            headerName: "Validity Till",
                            field: "validity_till",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Service Provider",
                            field: "service_provider",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable: true,
                        },
                        {
                            headerName: "Consent Re Registration Time",
                            field: "re_reg_date",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable: true,
                            valueGetter: function (params) {
                                if (params.data.re_reg_date != undefined && params.data.re_reg_date != "") {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.re_reg_date);
                                }
                                else {
                                    return "";
                                }
                            },
                        },
                        {
                            headerName: "Consent Register Time",
                            field: "reg_date",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable: true,
                            hide: true,
                            valueGetter: function (params) {
                                if (params.data.reg_date != undefined && params.data.reg_date != "") {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.reg_date);
                                }
                                else {
                                    return "";
                                }
                            }
                        },             
                    ],
                    rowData:records.jio_and_telenity_overall_data,
                    jio_and_telenity_overall_data:records.jio_and_telenity_overall_data,
                    jio_and_telenity_overall_data_columns:[
                        {
                            headerName: "Mobile No",
                            field: "mobile_no",
                            width: 120,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Consent Status",
                            field: "consentStatus",            
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        // {
                        //     headerName: "Network Type",
                        //     field: "networkType",
                        //     width: 120,
                        //     filter: true,
                        //     resizable: true,
                        //     sortable:true,
                        // },
                        {
                            headerName: "Service Provider",
                            field: "service_provider_name",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Source From",
                            field: "last_org_name",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        }, 
                        {
                            headerName: "Latitude",
                            field: "latitude",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Longitude",
                            field: "longitude",            
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Server Time",
                            field: "serverTime",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Registered Date",
                            field: "registeredDate",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.registeredDate !=undefined && params.data.registeredDate !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.registeredDate);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Approved Date",
                            field: "approvedDate",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.approvedDate !=undefined && params.data.approvedDate !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.approvedDate);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        
                        {
                            headerName: "Created Date",
                            field: "created_date_1",
                            width: 120,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.created_date_1 !=undefined && params.data.created_date_1 !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.created_date_1);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Consent Created On",
                            field: "consentCreatedOn",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.consentCreatedOn !=undefined && params.data.consentCreatedOn !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.consentCreatedOn);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Accuracy",
                            field: "accuracy",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },   
                        {
                            headerName: "Validity Till",
                            field: "validity_till",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                    ],
                    jio_live_data:records.jio_live_data,
                    jio_live_data_columns: [
                        {
                            headerName: "Mobile No",
                            field: "mobile_no",
                            width: 120,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Consent Status",
                            field: "consentStatus",            
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        // {
                        //     headerName: "Network Type",
                        //     field: "networkType",
                        //     width: 120,
                        //     filter: true,
                        //     resizable: true,
                        //     sortable:true,
                        // },
                        {
                            headerName: "Service Provider",
                            field: "service_provider_name",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Source From",
                            field: "last_org_name",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        }, 
                        {
                            headerName: "Latitude",
                            field: "latitude",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Longitude",
                            field: "longitude",            
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Server Time",
                            field: "serverTime",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Registered Date",
                            field: "registeredDate",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.registeredDate !=undefined && params.data.registeredDate !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.registeredDate);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Approved Date",
                            field: "approvedDate",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.approvedDate !=undefined && params.data.approvedDate !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.approvedDate);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        
                        {
                            headerName: "Created Date",
                            field: "created_date_1",
                            width: 120,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.created_date_1 !=undefined && params.data.created_date_1 !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.created_date_1);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Consent Created On",
                            field: "consentCreatedOn",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.consentCreatedOn !=undefined && params.data.consentCreatedOn !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.consentCreatedOn);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Accuracy",
                            field: "accuracy",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },   
                        {
                            headerName: "Validity Till",
                            field: "validity_till",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },   
                    ],
                    telenity_overall_data:records.telenity_overall_data,
                    telenity_overall_data_columns:[
                        {
                            headerName: "Mobile No",
                            field: "mobile_no",
                            width: 120,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Consent Status",
                            field: "consentStatus",            
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        // {
                        //     headerName: "Network Type",
                        //     field: "networkType",
                        //     width: 120,
                        //     filter: true,
                        //     resizable: true,
                        //     sortable:true,
                        // },
                        {
                            headerName: "Service Provider",
                            field: "service_provider_name",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Source From",
                            field: "last_org_name",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        }, 
                        {
                            headerName: "Latitude",
                            field: "latitude",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Longitude",
                            field: "longitude",            
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Server Time",
                            field: "serverTime",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Registered Date",
                            field: "registeredDate",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.registeredDate !=undefined && params.data.registeredDate !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.registeredDate);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Approved Date",
                            field: "approvedDate",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.approvedDate !=undefined && params.data.approvedDate !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.approvedDate);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        
                        {
                            headerName: "Created Date",
                            field: "created_date_1",
                            width: 120,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.created_date_1 !=undefined && params.data.created_date_1 !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.created_date_1);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Consent Created On",
                            field: "consentCreatedOn",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.consentCreatedOn !=undefined && params.data.consentCreatedOn !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.consentCreatedOn);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Accuracy",
                            field: "accuracy",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },   
                        {
                            headerName: "Validity Till",
                            field: "validity_till",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },,
                        {
                            headerName: "Service Provider",
                            field: "service_provider",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable: true,
                        },
                        {
                            headerName: "Consent Re Registration Time",
                            field: "re_reg_date",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable: true,
                            valueGetter: function (params) {
                                if (params.data.re_reg_date != undefined && params.data.re_reg_date != "") {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.re_reg_date);
                                }
                                else {
                                    return "";
                                }
                            },
                        },
                        {
                            headerName: "Consent Register Time",
                            field: "reg_date",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable: true,
                            hide: true,
                            valueGetter: function (params) {
                                if (params.data.reg_date != undefined && params.data.reg_date != "") {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.reg_date);
                                }
                                else {
                                    return "";
                                }
                            }
                        },             
                    ],
            
                    telenity_live_data:records.telenity_live_data,
                    telenity_live_data_columns:[
                        {
                            headerName: "Mobile No",
                            field: "mobile_no",
                            width: 120,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Consent Status",
                            field: "consentStatus",            
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        // {
                        //     headerName: "Network Type",
                        //     field: "networkType",
                        //     width: 120,
                        //     filter: true,
                        //     resizable: true,
                        //     sortable:true,
                        // },
                        {
                            headerName: "Service Provider",
                            field: "service_provider_name",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Latitude",
                            field: "latitude",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Longitude",
                            field: "longitude",            
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Server Time",
                            field: "serverTime",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Registered Date",
                            field: "registeredDate",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.registeredDate !=undefined && params.data.registeredDate !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.registeredDate);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Approved Date",
                            field: "approvedDate",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.approvedDate !=undefined && params.data.approvedDate !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.approvedDate);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        
                        {
                            headerName: "Created Date",
                            field: "created_date_1",
                            width: 120,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.created_date_1 !=undefined && params.data.created_date_1 !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.created_date_1);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Consent Created On",
                            field: "consentCreatedOn",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.consentCreatedOn !=undefined && params.data.consentCreatedOn !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.consentCreatedOn);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Accuracy",
                            field: "accuracy",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },   
                        {
                            headerName: "Validity Till",
                            field: "validity_till",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },   
                    ],
                    act_pending_data:records.act_pending_data,
                    act_pending_data_columns:[
                    {
                        headerName: "Mobile No",
                        field: "mobile_no",
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                    },
                    {
                        headerName: "Command Type",
                        field: "command",
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                    },
                    {
                        headerName: "Created Date",
                        field: "created_date",            
                        width: 250,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            if(params.data.created_date !=undefined && params.data.created_date !="")
                            {
                                return getHyphenDDMMYYYYHHMMSS(params.data.created_date);
                            }
                            else
                            {
                                return "";
                            }
                        }
                    },
                    // {
                    //     headerName: "consent_validity_days",
                    //     field: "consent_validity_days",
                    //     width: 150,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // },
                    
                    // {
                    //     headerName: "process",
                    //     field: "process",
                    //     width: 120,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // },
                    // {
                    //     headerName: "name",
                    //     field: "name",
                    //     width: 120,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // },
                    {
                        headerName: "Activation Status",
                        field: "process",
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            
                                console.log(params.data.process,"hello123")
                                if(params.data.process == 1){
                                    return "Failed"
                                }
                                else{
                                    return "Pending"
                                }
                            
                        }
                    },
                    {
                        headerName: "Source From",
                        field: "source_from",
                        width: 250,
                        filter: true,
                        resizable: true,
                        sortable:true,
                    }, 
                    {
                        headerName: "Service Provider",
                        field: "service_provider",
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            if(params.data.service_provider !=undefined && params.service_provider !="")
                            {
                                if(params.data.service_provider == 1){
                                    return "JIO"
                                }
                                else if(params.data.service_provider == 2){
                                    return "TELENITY"
                                }
                                else{
                                    return ""
                                }
                            }
                            else
                            {
                                return "";
                            }
                        }
                    }, 
                    // {
                    //     headerName: "sim_tracking_service_provider",
                    //     field: "sim_tracking_service_provider",
                    //     width: 150,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // }, 
                   
                    {
                        headerName: "Type of Registration",
                        field: "type_of_reg",
                        width: 250,
                        filter: true,
                        resizable: true,
                        sortable:true,
                    }, ],
                    deact_pending_data:records.deact_pending_data,
                    deact_pending_data_columns:[
                        {
                            headerName: "Mobile No",
                            field: "mobile_no",
                            width: 200,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                        headerName: "Command Type",
                        field: "command",
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                    },
                    {
                        headerName: "Created Date",
                        field: "created_date",            
                        width: 250,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            if(params.data.created_date !=undefined && params.data.created_date !="")
                            {
                                return getHyphenDDMMYYYYHHMMSS(params.data.created_date);
                            }
                            else
                            {
                                return "";
                            }
                        }
                    },
                    {
                        headerName: "Modified Date",
                        field: "modified_date",
                        width: 250,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            if(params.data.modified_date !=undefined && params.data.modified_date !="")
                            {
                                return getHyphenDDMMYYYYHHMMSS(params.data.modified_date);
                            }
                            else
                            {
                                return "";
                            }
                        }
                    },
                    
                    // {
                    //     headerName: "process",
                    //     field: "process",
                    //     width: 120,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // },
                    {
                        headerName: "Deactivation Status",
                        field: "process",
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            console.log(params.data.process,"321")
                            
                                if(params.data.process == 1){
                                    return "Failed"
                                }
                                else{
                                    return "Pending"
                                }
                            
                        }
                    },
                    {
                        headerName: "Source From",
                        field: "source_from",
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                    },
                    {
                        headerName: "Service Provider",
                        field: "service_provider",
                        width: 250,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            if(params.data.service_provider !=undefined && params.service_provider !="")
                            {
                                if(params.data.service_provider == 1){
                                    return "JIO"
                                }
                                else if(params.data.service_provider == 2){
                                    return "TELENITY"
                                }
                                else{
                                    return ""
                                }
                            }
                            else
                            {
                                return "";
                            }
                        }
                    }, 
                    // {
                    //     headerName: "sim_tracking_service_provider",
                    //     field: "sim_tracking_service_provider",
                    //     width: 150,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // },  
                     ],
                    invalid_act_data:records.invalid_act_data,
                    invalid_act_data_columns:[ {
                        headerName: "Mobile No",
                        field: "mobile_no",
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                    },
                    {
                        headerName: "Command Type",
                        field: "command",
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                    },
                    {
                        headerName: "Created Date",
                        field: "created_date",            
                        width: 250,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            if(params.data.created_date !=undefined && params.data.created_date !="")
                            {
                                return getHyphenDDMMYYYYHHMMSS(params.data.created_date);
                            }
                            else
                            {
                                return "";
                            }
                        }
                    },
                    // {
                    //     headerName: "consent_validity_days",
                    //     field: "consent_validity_days",
                    //     width: 150,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // },
                    
                    // {
                    //     headerName: "process",
                    //     field: "process",
                    //     width: 120,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // },
                    // {
                    //     headerName: "name",
                    //     field: "name",
                    //     width: 120,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // },
                    {
                        headerName: "Activation Status",
                        field: "process",
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            
                                if(params.data.process == 1){
                                    return "Failed"
                                }
                                else{
                                    return "Pending"
                                }
                            
                        }
                    },
                    {
                        headerName: "Source From",
                        field: "source_from",
                        width: 250,
                        filter: true,
                        resizable: true,
                        sortable:true,
                    }, 
                    {
                        headerName: "Service Provider",
                        field: "service_provider",
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            if(params.data.service_provider !=undefined && params.service_provider !="")
                            {
                                if(params.data.service_provider == 1){
                                    return "JIO"
                                }
                                else if(params.data.service_provider == 2){
                                    return "TELENITY"
                                }
                                else{
                                    return ""
                                }
                            }
                            else
                            {
                                return "";
                            }
                        }
                    }, 
                    // {
                    //     headerName: "sim_tracking_service_provider",
                    //     field: "sim_tracking_service_provider",
                    //     width: 150,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // }, 
                   
                    {
                        headerName: "Type of Registration",
                        field: "type_of_reg",
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                    },
                    {
                        headerName: "Modified Date",
                        field: "modified_date",
                        width: 150,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            if(params.data.modified_date !=undefined && params.data.modified_date !="")
                            {
                                return getHyphenDDMMYYYYHHMMSS(params.data.modified_date);
                            }
                            else
                            {
                                return "";
                            }
                        }
                    },
                    {
                        headerName: "Service Provider Response",
                        field: "resp",
                        width: 250,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            if(params.data.resp != null){
                                return params.data.resp.message;
                            }
                            else{
                                return "";
                            }
                        }
                    } ],
                    invalid_deact_data:records.invalid_deact_data,
                    invalid_deact_data_columns:[
                        {
                            headerName: "Mobile No",
                            field: "mobile_no",
                            width: 200,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                        headerName: "Command Type",
                        field: "command",
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                    },
                    {
                        headerName: "Created Date",
                        field: "created_date",            
                        width: 250,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            if(params.data.created_date !=undefined && params.data.created_date !="")
                            {
                                return getHyphenDDMMYYYYHHMMSS(params.data.created_date);
                            }
                            else
                            {
                                return "";
                            }
                        }
                    },
                    {
                        headerName: "Modified Date",
                        field: "modified_date",
                        width: 250,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            if(params.data.modified_date !=undefined && params.data.modified_date !="")
                            {
                                return getHyphenDDMMYYYYHHMMSS(params.data.modified_date);
                            }
                            else
                            {
                                return "";
                            }
                        }
                    },
                    
                    // {
                    //     headerName: "process",
                    //     field: "process",
                    //     width: 120,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // },
                    {
                        headerName: "Deactivation Status",
                        field: "process",
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            console.log(params.data)
                            
                                if(params.data.process == 1){
                                    return "Failed"
                                }
                                else{
                                    return "Pending"
                                }
                            
                        }
                    },
                    {
                        headerName: "Source From",
                        field: "source_from",
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                    },
                    {
                        headerName: "Service Provider",
                        field: "service_provider",
                        width: 250,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            if(params.data.service_provider !=undefined && params.service_provider !="")
                            {
                                if(params.data.service_provider == 1){
                                    return "JIO"
                                }
                                else if(params.data.service_provider == 2){
                                    return "TELENITY"
                                }
                                else{
                                    return ""
                                }
                            }
                            else
                            {
                                return "";
                            }
                        }
                    }, 
                    // {
                    //     headerName: "sim_tracking_service_provider",
                    //     field: "sim_tracking_service_provider",
                    //     width: 150,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // },  
                     ],
                    invalid_reg_data:records.invalid_reg_data,
                    invalid_reg_data_columns:[
                        {
                            headerName: "Mobile No",
                            field: "mobile_no",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                        headerName: "Command Type",
                        field: "command",
                        width: 150,
                        filter: true,
                        resizable: true,
                        sortable:true,
                    },
                    // {
                    //     headerName: "consent_validity_days",
                    //     field: "consent_validity_days",
                    //     width: 120,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // },
                    {
                        headerName: "Created Date",
                        field: "created_date",            
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            if(params.data.created_date !=undefined && params.data.created_date !="")
                            {
                                return getHyphenDDMMYYYYHHMMSS(params.data.created_date);
                            }
                            else
                            {
                                return "";
                            }
                        }
                    },
                    {
                        headerName: "Modified Date",
                        field: "modified_date",
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            if(params.data.modified_date !=undefined && params.data.modified_date !="")
                            {
                                return getHyphenDDMMYYYYHHMMSS(params.data.modified_date);
                            }
                            else
                            {
                                return "";
                            }
                        }
                    },
                    
                    // {
                    //     headerName: "name",
                    //     field: "name",
                    //     width: 150,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true
                    // },
                    {
                        headerName: "Registration Status",
                        field: "process",
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            
                                if(params.data.process == 1){
                                    return "Failed"
                                }
                                else{
                                    return "Pending"
                                }
                            
                        }
                    },
                    {
                        headerName: "Service Provider Response",
                        field: "resp",
                        width: 250,
                        filter: true,
                        resizable: true,
                        sortable:true
                    },
                    // {
                    //     headerName: "process",
                    //     field: "process",
                    //     width: 120,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // },
                    {
                        headerName: "Service Provider",
                        field: "service_provider",
                        width: 150,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            if(params.data.service_provider !=undefined && params.service_provider !="")
                            {
                                if(params.data.service_provider == 1){
                                    return "JIO"
                                }
                                else if(params.data.service_provider == 2){
                                    return "TELENITY"
                                }
                                else{
                                    return ""
                                }
                            }
                            else
                            {
                                return "";
                            }
                        }
                    }, 
                    
                    // {
                    //     headerName: "sim_reg_time",
                    //     field: "sim_reg_time",
                    //     width: 120,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // },
                    // {
                    //     headerName: "sim_reg_timeout_min",
                    //     field: "sim_reg_timeout_min",
                    //     width: 220,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // },
                    // {
                    //     headerName: "sim_tracking_service_provider",
                    //     field: "sim_tracking_service_provider",
                    //     width: 150,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // }, 
                    {
                        headerName: "Source From",
                        field: "source_from",
                        width: 150,
                        filter: true,
                        resizable: true,
                        sortable:true,
                    }, 
                    {
                        headerName: "Type of Registration",
                        field: "type_of_reg",
                        width: 150,
                        filter: true,
                        resizable: true,
                        sortable:true,
                    }, 
                    // {
                    //     headerName: "ID",
                    //     field: "_id",
                    //     width: 150,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // }, 
                ],
                    reg_pending_data:records.reg_pending_data,
                    reg_pending_data_columns:[
                        {
                            headerName: "Mobile No",
                            field: "mobile_no",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                        headerName: "Command Type",
                        field: "command",
                        width: 150,
                        filter: true,
                        resizable: true,
                        sortable:true,
                    },
                    // {
                    //     headerName: "consent_validity_days",
                    //     field: "consent_validity_days",
                    //     width: 120,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // },
                    {
                        headerName: "Created Date",
                        field: "created_date",            
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            if(params.data.created_date !=undefined && params.data.created_date !="")
                            {
                                return getHyphenDDMMYYYYHHMMSS(params.data.created_date);
                            }
                            else
                            {
                                return "";
                            }
                        }
                    },
                    {
                        headerName: "Modified Date",
                        field: "modified_date",
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            if(params.data.modified_date !=undefined && params.data.modified_date !="")
                            {
                                return getHyphenDDMMYYYYHHMMSS(params.data.modified_date);
                            }
                            else
                            {
                                return "";
                            }
                        }
                    },
                    
                    // {
                    //     headerName: "name",
                    //     field: "name",
                    //     width: 150,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true     
                    // },
                    {
                        headerName: "Registration Status",
                        field: "process",
                        width: 200,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            console.log(params.data.process,"check123")
                            
                                if(params.data.process == 1){
                                    return "Failed"
                                }
                                else{
                                    return "Pending"
                                }
                            
                        }
                    },
                    {
                        headerName: "Service Provider Response",
                        field: "resp",
                        width: 250,
                        filter: true,
                        resizable: true,
                        sortable:true
                    },
                    // {
                    //     headerName: "process",
                    //     field: "process",
                    //     width: 120,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // },
                    {
                        headerName: "Service Provider",
                        field: "service_provider",
                        width: 150,
                        filter: true,
                        resizable: true,
                        sortable:true,
                        valueGetter:function(params){
                            if(params.data.service_provider !=undefined && params.service_provider !="")
                            {
                                if(params.data.service_provider == 1){
                                    return "JIO"
                                }
                                else if(params.data.service_provider == 2){
                                    return "TELENITY"
                                }
                                else{
                                    return ""
                                }
                            }
                            else
                            {
                                return "";
                            }
                        }
                    }, 
                    
                    // {
                    //     headerName: "sim_reg_time",
                    //     field: "sim_reg_time",
                    //     width: 120,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // },
                    // {
                    //     headerName: "sim_reg_timeout_min",
                    //     field: "sim_reg_timeout_min",
                    //     width: 220,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // },
                    // {
                    //     headerName: "sim_tracking_service_provider",
                    //     field: "sim_tracking_service_provider",
                    //     width: 150,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // }, 
                    {
                        headerName: "Source From",
                        field: "source_from",
                        width: 150,
                        filter: true,
                        resizable: true,
                        sortable:true,
                    }, 
                    {
                        headerName: "Type of Registration",
                        field: "type_of_reg",
                        width: 150,
                        filter: true,
                        resizable: true,
                        sortable:true,
                    }, 
                    // {
                    //     headerName: "ID",
                    //     field: "_id",
                    //     width: 150,
                    //     filter: true,
                    //     resizable: true,
                    //     sortable:true,
                    // }, 
                ],

                    jio_consent_used_licenses:getConsentApprovedUsedLicense(records),
                    jio_consent_used_licenses_columns:[
                        {
                            headerName: "Mobile No",
                            field: "mobile_no",
                            width: 120,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Consent Status",
                            field: "consentStatus",            
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        // {
                        //     headerName: "Network Type",
                        //     field: "networkType",
                        //     width: 120,
                        //     filter: true,
                        //     resizable: true,
                        //     sortable:true,
                        // },
                        {
                            headerName: "Service Provider",
                            field: "service_provider_name",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Source From",
                            field: "last_org_name",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        }, 
                        {
                            headerName: "Latitude",
                            field: "latitude",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Longitude",
                            field: "longitude",            
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Server Time",
                            field: "serverTime",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Registered Date",
                            field: "registeredDate",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.registeredDate !=undefined && params.data.registeredDate !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.registeredDate);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Approved Date",
                            field: "approvedDate",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.approvedDate !=undefined && params.data.approvedDate !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.approvedDate);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        
                        {
                            headerName: "Created Date",
                            field: "created_date_1",
                            width: 120,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.created_date_1 !=undefined && params.data.created_date_1 !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.created_date_1);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Consent Created On",
                            field: "consentCreatedOn",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.consentCreatedOn !=undefined && params.data.consentCreatedOn !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.consentCreatedOn);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Accuracy",
                            field: "accuracy",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },   
                        {
                            headerName: "Validity Till",
                            field: "validity_till",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },   
                    ],
                    
                    telenity_used_licenses_data:records.telenity_live_data,
                    telenity_used_licenses_data_columns:[
                        {
                            headerName: "Mobile No",
                            field: "mobile_no",
                            width: 120,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Consent Status",
                            field: "consentStatus",            
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        // {
                        //     headerName: "Network Type",
                        //     field: "networkType",
                        //     width: 120,
                        //     filter: true,
                        //     resizable: true,
                        //     sortable:true,
                        // },
                        {
                            headerName: "Service Provider",
                            field: "service_provider_name",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Source From",
                            field: "last_org_name",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        }, 
                        {
                            headerName: "Latitude",
                            field: "latitude",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Longitude",
                            field: "longitude",            
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Server Time",
                            field: "serverTime",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Registered Date",
                            field: "registeredDate",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.registeredDate !=undefined && params.data.registeredDate !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.registeredDate);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Approved Date",
                            field: "approvedDate",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.approvedDate !=undefined && params.data.approvedDate !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.approvedDate);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        
                        {
                            headerName: "Created Date",
                            field: "created_date_1",
                            width: 120,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.created_date_1 !=undefined && params.data.created_date_1 !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.created_date_1);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Consent Created On",
                            field: "consentCreatedOn",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.consentCreatedOn !=undefined && params.data.consentCreatedOn !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.consentCreatedOn);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Accuracy",
                            field: "accuracy",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },   
                        {
                            headerName: "Validity Till",
                            field: "validity_till",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Location Retrieval Status",
                            field: "location_retrieval_status",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Location Result Status Text",
                            field: "location_result_status_text",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        }
                        ,
                        {
                            headerName: "Service Provider",
                            field: "service_provider",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable: true,
                        },
                        {
                            headerName: "Consent Re Registration Time",
                            field: "re_reg_date",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable: true,
                            valueGetter: function (params) {
                                if (params.data.re_reg_date != undefined && params.data.re_reg_date != "") {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.re_reg_date);
                                }
                                else {
                                    return "";
                                }
                            },
                        },
                        {
                            headerName: "Consent Register Time",
                            field: "reg_date",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable: true,
                            hide: true,
                            valueGetter: function (params) {
                                if (params.data.reg_date != undefined && params.data.reg_date != "") {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.reg_date);
                                }
                                else {
                                    return "";
                                }
                            }
                        },             
                    ],
                    
                     
                    // deact_overall_data:this.state.deact_pending_data.concat(this.state.invalid_deact_data),
                    // deact_overall_data_columns:this.state.deact_pending_data_columns.concat(this.stateinvalid_deact_data_columns)
                })

                this.setState((prevState) => ({
                    deact_overall_data: prevState.deact_pending_data.concat(prevState.invalid_deact_data),
                    deact_overall_data_columns: prevState.invalid_deact_data_columns,
                    act_overall_data:prevState.act_pending_data.concat(prevState.invalid_act_data),
                    act_overall_data_columns:prevState.invalid_act_data_columns,
                    reg_overall_data:prevState.reg_pending_data.concat(prevState.invalid_reg_data),
                    reg_overall_data_columns:prevState.invalid_reg_data_columns,
                    overall_used_licenses:prevState.jio_consent_used_licenses.concat(prevState.telenity_used_licenses_data),
                    overall_used_licenses_columns:prevState.telenity_used_licenses_data_columns

                  }));
                  
            })
        
        redirectURL.post("/amns/getIvrLogsData")
            .then(response => {
                var records = response.data
                console.log(records,"ivrrecords")
                this.setState({
                    ivrrecords : records,
                    ivrcolumns:[
                        {
                            headerName: "Mobile No",
                            field: "mobile_no",
                            width: 120,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Actual Consent Status",
                            field: "actual_consent_status",            
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Processed Consent Status",
                            field: "processed_consent_status",            
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Expected Entity Status",
                            field: "expected_entity_status",            
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        },
                        {
                            headerName: "Consent Created at Service Provider",
                            field: "consent_created_date_at_telenity",
                            width: 200,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.consent_created_date_at_telenity !=undefined && params.data.consent_created_date_at_telenity !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.consent_created_date_at_telenity);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Source From",
                            field: "source_from",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        }, 
                        {
                            headerName: "Source Type",
                            field: "source_type",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                        }, 
                        {
                            headerName: "Created Date",
                            field: "created_date",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.created_date !=undefined && params.data.created_date !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.created_date);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                        {
                            headerName: "Modified Date",
                            field: "modified_date",
                            width: 150,
                            filter: true,
                            resizable: true,
                            sortable:true,
                            valueGetter:function(params){
                                if(params.data.modified_date !=undefined && params.data.modified_date !="")
                                {
                                    return getHyphenDDMMYYYYHHMMSS(params.data.modified_date);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        },
                    ]
                })
            })
    }

	onClickHideAll(){
		this.setState({
			overly : "show-n",
			showTrucksList : "show-n",
			sliderRouteTranslate:'',
            sliderRouteIVR: ""
		})
	}
	

    onGridReady = params => {
		this.gridApi = params.api;
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
    };
    

    onClickTab = (counterKey,headerName) => 
    {
        if(counterKey != ""){
            this.setState({
                aggrid_or_charts:true,
                rowData : this.state[counterKey],
                columnWithDefs:this.state[`${counterKey}_columns`],
                headerName:headerName
            })
        }
        else{
            this.setState({
                aggrid_or_charts:true,
                rowData : [],
                columnWithDefs:[],
                headerName:headerName
            })
        }
        
    }

    onClickShowChartDashboard = (counterKey) => {
        this.setState({
            app_hit_count_data : this.state[counterKey]
        })
    }

    onClickToToggleData = () => {
        this.setState({
            aggrid_or_charts:!this.state.aggrid_or_charts,
            // headerName:"Sim Tracking Info"
        })
        console.log(this.state.aggrid_or_charts , 'aggrid_or_charts')
        if(this.state.aggrid_or_charts == true){
            this.setState({
                prevHeadername :this.state.headerName,
                headerName:"Sim Tracking Info"
            })
        }else{
            this.setState({
                headerName:this.state.prevHeadername,
                prevHeadername:''
            })
        }
        
        
    }
 
    onClickToViewIvrData = () => {
        this.setState({
			sliderRouteIVR: 'slider-translate-75p',
			overly: 'show-m',
			loadshow:"show-n"
		})
		

	}
    

 onAddRow() {
		this.setState({
			sliderRouteTranslate: 'slider-translate',
			selectedRowNode: "",
			overly: 'show-m',
			plant: { "value": "", "label": "Plant Location" },
			dealer: { "value": "", "label": "Dealer" },
			oem: { "value": "", "label": "OEM" },
			driver: { "value": "", "label": "Driver" },
			truck_no: "",
			trip_no: "",
			vin_no: "",
			invoice_date: "",
			gate_in_time: "",
			gate_out_time: ""
		})
		$("#invoice_date").val("__-__-____ __:__");
		$("#gate_in_time").val("__-__-____ __:__");
		$("#gate_out_time").val("__-__-____ __:__");
		$("#invoice_date").val("");
		$("#gate_in_time").val("");
		$("#gate_out_time").val("");

	}


    render(){
        return (
            
    		<div className={(this.state.screenView == "Mobile")?"":"container-fluid"} style={(this.state.screenView == "Mobile")?{marginTop:"2em"}: {}}>
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
                >
                </SweetAlert>
                
                <div className="row mt-10p">
                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect"> 
                        <div className={'col-xl-12 col-lg-12'} style={{display: 'flex', justifyContent: 'flex-end'}} >
                            {/* <button className={'btn btn-default'} style={{ paddingLeft: "15px" , marginLeft:"-16px" }}>Projects Using Sim Tracking</button> */}
                            <button type="button" style={{marginRight:"-15px"}} onClick={this.onAddRow.bind(this)} className={'btn btn-info'} >Customers Using Sim Tracking</button>
                        </div>
                        <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                     <div className="row">
                                                <div className="col cursorPointer">
                                                    <span className="f13">Registered Numbers <span className="f13">*</span></span>
                                                    <h4 className="txt-success f25" onClick={this.onClickTab.bind(this,"jio_and_telenity_overall_data","Registered Numbers")}><span className="counter"><CountUp end={this.state.mobile_nos_reg_in_jio_n_telenity}/></span></h4>
                                                    <div className="row">                                        
                                                <div className="col cursorPointer" onClick={this.onClickTab.bind(this,"jio_live_data","Registered Numbers - JIO")}>
                                                    <span className="f10">Jio</span>
                                                    <h4 className="txt-success f25"><span className="counter"><CountUp end={this.state.mobile_nos_reg_in_jio}/></span></h4>
                                                </div>
                                                <div className="col cursorPointer" onClick={this.onClickTab.bind(this,"telenity_overall_data","Registered Numbers - TELENITY")}>
                                                    <span className="f10">Telenity</span>
                                                    <h4 className="txt-success f25"><span className="counter"><CountUp end={this.state.mobile_nos_reg_in_telenity}/></span></h4>
                                                    {/* <div className="row">                                        
                                                <div className="col cursorPointer" onClick={this.onClickTab.bind(this,"telenity_live_data")}>
                                                    <span className="f10">Live</span>
                                                    <h4 className="txt-success f15"><span className="counter"><CountUp end={this.state.telenity_live_data_count}/></span></h4>
                                                </div>
                                            </div> */}
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="col cursorPointer">
                                        <span className="f13">Total Licenses</span>
                                            <h4 className="txt-success f25" onClick={this.onClickTab.bind(this,"","Sim Tracking Info")}><span className="counter"><CountUp end={this.state.total_allocated_licenses}/></span></h4>
                                            <div className="row">                                        
                                                <div className="col cursorPointer" onClick={this.onClickTab.bind(this,"","Sim Tracking Info")}>
                                                    <span className="f10">Jio</span>
                                                    <h4 className="txt-success f25"><span className="counter"><CountUp end={this.state.jio_allocated_licenses}/></span></h4>
                                                </div>
                                                <div className="col cursorPointer" onClick={this.onClickTab.bind(this,"","Sim Tracking Info")}>
                                                    <span className="f10">Telenity</span>
                                                    <h4 className="txt-success f25"><span className="counter"><CountUp end={this.state.telenity_allocated_licenses}/></span></h4>
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="col cursorPointer">
                                        <span className="f13">Available Licenses</span>
                                            <h4 className="txt-secondary f25" onClick={this.onClickTab.bind(this,"","Sim Tracking Info")}><span className="counter"><CountUp end={this.state.total_available_licenses}/></span></h4>
                                            <div className="row">                                        
                                                <div className="col cursorPointer" onClick={this.onClickTab.bind(this,"","Sim Tracking Info")}>
                                                    <span className="f10">Jio</span>
                                                    <h4 className="txt-secondary f25"><span className="counter"><CountUp end={this.state.jio_available_licenses}/></span></h4>
                                                </div>
                                                <div className="col cursorPointer" onClick={this.onClickTab.bind(this,"","Sim Tracking Info")}>
                                                    <span className="f10">Telenity</span>
                                                    <h4 className="txt-secondary f25"><span className="counter"><CountUp end={this.state.telenity_available_licenses}/></span></h4>
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="col cursorPointer">
                                        <span className="f13">Used Licenses <span className="f13">*</span></span>
                                            <h4 className="txt-warning f25" onClick={this.onClickTab.bind(this,"overall_used_licenses","Used Licenses")}><span className="counter"><CountUp end={this.state.total_used_licenses}/></span></h4>
                                            <div className="row">                                        
                                                <div className="col cursorPointer" onClick={this.onClickTab.bind(this,"jio_consent_used_licenses","Used Licenses - JIO")}>
                                                    <span className="f10">Jio</span>
                                                    <h4 className="txt-warning f25"><span className="counter"><CountUp end={this.state.jio_used_licenses}/></span></h4>
                                                </div>
                                                <div className="col cursorPointer" onClick={this.onClickTab.bind(this,"telenity_used_licenses_data","Used Licenses - TELENITY")}>
                                                    <span className="f10">Telinity</span>
                                                    <h4 className="txt-warning f25"><span className="counter"><CountUp end={this.state.telenity_used_licenses}/></span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col cursorPointer">
                                        <span className="f13">Yet to Register <span className="f13">*</span> </span>
                                            <h4 className="txt-info f25" onClick={this.onClickTab.bind(this,"reg_overall_data","Yet to Register")}><span className="counter"><CountUp end={this.state.reg_data_count}/></span></h4>
                                            <div className="row">                                        
                                                <div className="col cursorPointer" onClick={this.onClickTab.bind(this,"reg_pending_data","Yet to Register - Pending")}>
                                                    <span className="f10">Pending</span>
                                                    <h4 className="txt-info f25"><span className="counter"><CountUp end={this.state.reg_pending_data_count}/></span></h4>
                                                </div>
                                                <div className="col cursorPointer" onClick={this.onClickTab.bind(this,"invalid_reg_data","Yet to Register - Failed")}>
                                                    <span className="f10">Failed</span>
                                                    <h4 className="txt-info f25"><span className="counter"><CountUp end={this.state.invalid_reg_data_count}/></span></h4>
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="col cursorPointer">
                                        <span className="f13">Yet to Activate <span className="f13">*</span></span>
                                            <h4 className="txt-primary f25" onClick={this.onClickTab.bind(this,"act_overall_data","Yet to Activate")}><span className="counter"><CountUp end={this.state.act_data_count}/></span></h4>
                                            <div className="row">                                        
                                                <div className="col cursorPointer" onClick={this.onClickTab.bind(this,"act_pending_data","Yet to Activate - Pending")}>
                                                    <span className="f10">Pending</span>
                                                    <h4 className="txt-primary f25"><span className="counter"><CountUp end={this.state.act_pending_data_count}/></span></h4>
                                                </div>
                                                <div className="col cursorPointer" onClick={this.onClickTab.bind(this,"invalid_act_data","Yet to Activate - Failed")}>
                                                    <span className="f10">Failed</span>
                                                    <h4 className="txt-primary f25"><span className="counter"><CountUp end={this.state.invalid_act_data_count}/></span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col cursorPointer">
                                        <span className="f13">Yet to Deactivate <span className="f13">*</span></span>
                                            <h4 className="txt-danger f25" onClick={this.onClickTab.bind(this,"deact_overall_data","Yet to Deactivate")}><span className="counter"><CountUp end={this.state.deact_data_count}/></span></h4>
                                            <div className="row">                                        
                                                <div className="col cursorPointer" onClick={this.onClickTab.bind(this,"deact_pending_data","Yet to Deactivate - Pending")}>
                                                    <span className="f10">Pending</span>
                                                    <h4 className="txt-danger f25"><span className="counter"><CountUp end={this.state.deact_pending_data_count}/></span></h4>
                                                </div>
                                                <div className="col cursorPointer" onClick={this.onClickTab.bind(this,"invalid_deact_data","Yet to Deactivate - Failed")}>
                                                    <span className="f10">Failed</span>
                                                    <h4 className="txt-danger f25"><span className="counter"><CountUp end={this.state.invalid_deact_data_count}/></span></h4>
                                                </div>
                                            </div>
                                        </div>                          
                                    </div>                               
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header">
                        <h5>
							<i class="icofont icofont-vehicle-delivery-van cus-i"></i>
                        	{this.state.headerName}
                        </h5>
                        {/* <div style={{display: 'flex', justifyContent: 'flex-end'}}> */}
                        {/* <i class="icofont icofont-swoosh-right"></i> */}

                        <button type="button" className={'btn btn-info float-right'} onClick={this.onClickToToggleData.bind(this)}>
                        {this.state.aggrid_or_charts?"Show Charts":"Show Data"}</button>
                        <button type="button" className={'btn btn-primary float-right'} onClick={this.onClickToViewIvrData}>View IVR Data</button>
                        {/* </div> */}
                    </div>
                    <div className={(this.state.screenView == "Mobile")?"card-body":"card-body col-xl-12 col-lg-12"}>
                        {this.state.aggrid_or_charts?
                            <div id="myGrid" style={{width:"100%",height:"550px"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={this.state.columnWithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={1000}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this },
                                }}
                                // components={this.state.components}
                                enableRangeSelection= {true}
                                // onCellClicked={this.onCellClickedInTransit.bind(this)}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                            </div>
                            :
                            <div>
                                {/* <button type="button" className="btn btn-primary" onClick={this.onClickShowChartDashboard.bind(this,"app_hit_count_data")}>Chart Dashboard</button> */}
                                <div className='d-flex flex-row justify-between'>
                                {this.state.app_hit_count_data.length > 0 && <SimTrackingDashboardCharts applicationCount = {this.state.app_hit_count_data} />}
                                {this.state.app_hit_count_data.length > 0 && <SimTrackingDashboardAPICharts applicationCount = {this.state.app_hit_count_data} />}
                                </div>
                                
                            </div>
                        } 
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

				{this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								Customers Using Sim Tracking
                                <span className="float-right closebtn" style={{marginRight:"25px"}} 
                                onClick={this.onClickHideAll.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                            <ul style={{listStyleType: 'none',padding: 0,margin: 0,}}>
                                {this.state.applications_using_sim_tracking.map((item, index) => (
                                    <li key={index} style={{
                                        backgroundColor: '#f2f2f2',
                                        border: '1px solid #ddd',
                                        margin: '5px',
                                        padding: '10px',
                                        borderRadius: '5px',
                                      }}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
							</div>
                        </div>
                    </div>
                :""}

                {this.state.sliderRouteIVR != ''?
					 <div className={"slide-r "+(this.state.sliderRouteIVR)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								IVR Data Logs
                                <span className="float-right closebtn" style={{marginRight:"25px"}} 
                                onClick={this.onClickHideAll.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                            <div id="myGrid" style={{width:"100%",height:"700px"}} className={"ag-theme-balham"}>    
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={this.state.ivrcolumns}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.ivrrecords}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus= {true}
                                    paginationPageSize={1000}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this },
                                    }}
                                    // components={this.state.components}
                                    enableRangeSelection= {true}
                                    // onCellClicked={this.onCellClickedInTransit.bind(this)}
                                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                    />
                                </div>
							</div>
                        </div>
                    </div>
                :""}
				
            </div>
              
        );
    }
}


// eslint-disable-next-line no-unused-vars
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		format:'Y-m-d',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

$(document).on("change", "#toDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#fromDate").val();
    var cargoDate=$("#toDate").val();
    // var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]
    console.log()
    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime,endTime);
    if(startTime > endTime)
    {
        $("#toDate").val("");
        alert("To Date should be greater than or equal to From Date");
    }
})

$(document).on("change", "#fromDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#fromDate").val();
    var cargoDate=$("#toDate").val();
    // var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime,endTime);
    if(startTime > endTime)
    {
        $("#fromDate").val("");
        alert("From Date should be less than equal to To Date");
    }
})

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd hh:mm",
        changeMonth: true,
        changeYear: true,
        timepicker: true,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};


function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function secondsToHM(seconds) {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600*24));
    // var h = Math.floor(seconds % (3600*24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);
    
    // var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    // var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    // var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return dDisplay + hDisplay + mDisplay;
    
    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh*3600));
    var mm = Math.floor(hrr/60)
    if(mm < 10)
    {
        var mins = "0"+mm;
    }
    else
    {
        var mins = mm;
    }

    if(hh < 10)
    {
        var hrs = "0"+hh;
    }
    else
    {
        var hrs = hh;
    }
    return hrs+":"+mins;
}
function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}