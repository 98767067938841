import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import { assign, matchesProperty, transform } from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


var Constant = require('../common/Constant');
$.datetimepicker.setLocale('en');

var redirectURL = require('../redirectURL');
var moment = require('moment');
const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

class TruckLoadSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            basicType: "",
            basicTitle: "",
            loadshow: "show-n",
            overly: "show-n",
            url_path: window.location.pathname,
            truck_load_data: [],
            truck_load_zones: [{ value: 'AHMEDABAD', label: 'AHMEDABAD' }],
            truck_load_zone: { value: 'AHMEDABAD', label: 'AHMEDABAD' },
            zone_truck_load: {},
            zone_wise_data: [],
            yesterday_zone_wise_data: [],

            zone_wise_data: [],
            yesterday_zone_wise_data: [],

            road_data_arr: [],
            rail_data_arr: [],
            stack_intrasit_data: [],
            mode_change_data: [],
        };
    }

    componentDidMount() {
        this.onShowZoneList()

    }
    onClickHideAll = () => {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
        })
    }
    fetchTruckLoadSummary = async (truck_load_zone) => {
        try {
            this.setState({
                loadshow: "show-m",
                overly: "show-m",
                zone_truck_load: truck_load_zone
            });
            var truck_load_zone = truck_load_zone

            const todayData = await this.fetchDataForDate(moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month'), truck_load_zone);

            this.setState({
                loadshow: "show-n",
                overly: "show-n",
                truck_load_data: todayData,

            });
        } catch (error) {
            console.error("Error fetching data:", error);
            // Optionally, you can set state here to handle error states
        }
    }

    fetchDataForDate = async (startDate, endDate, truck_load_zone) => {
        try {
            const start_date = startDate.format('YYYY-MM-DD HH:mm:ss');
            const end_date = endDate.format('YYYY-MM-DD HH:mm:ss');
            const parameters = { start_date, end_date };
            if (truck_load_zone != '' & truck_load_zone !== "All") {
                parameters.truck_load_zone = truck_load_zone
            }
            if (localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "undefined") {
                parameters.plant_code = encode(localStorage.getItem("pc"));
            }
            const response = await redirectURL.post("/consignments/truckLoadSummary", parameters);

            let data = response.data || [];
            if (!Array.isArray(data)) {
                data = []
            }

            const defaultMonthData = {
                qty_40mt: 0,
                qty_32mt: 0,
                qty_27mt: 0,
                item_quantity_others: 0
            };

            const defaultYearData = {
                qty_40mt: 0,
                qty_32mt: 0,
                qty_27mt: 0,
                item_quantity_others: 0
            };

            const defaultYesterdayData = {
                qty_40mt: 0,
                qty_32mt: 0,
                qty_27mt: 0,
                item_quantity_others: 0
            };
            // Total Row Fields
            const totalDefaultMonthData = {
                qty_40mt: 0,
                qty_32mt: 0,
                qty_27mt: 0,
                item_quantity_others: 0
            };

            const totalDefaultYearData = {
                qty_40mt: 0,
                qty_32mt: 0,
                qty_27mt: 0,
                item_quantity_others: 0
            };

            const totalDefaultYesterdayData = {
                qty_40mt: 0,
                qty_32mt: 0,
                qty_27mt: 0,
                item_quantity_others: 0
            };

            const sanitizeObject = (obj, timeObject) => {
                if (!obj.qty_40mt) {
                    obj.qty_40mt = 0;
                }
                if (!obj.qty_32mt) {
                    obj.qty_32mt = 0;
                }
                if (!obj.qty_27mt) {
                    obj.qty_27mt = 0;
                }
                if (!obj.item_quantity_others) {
                    obj.item_quantity_others = 0;
                }
                // Total Row Data 
                timeObject.qty_40mt += obj.qty_40mt;
                timeObject.qty_32mt += obj.qty_32mt;
                timeObject.qty_27mt += obj.qty_27mt;
                timeObject.item_quantity_others += obj.item_quantity_others;
                // Rounding values to two decimals 
                timeObject.qty_40mt = Math.round(timeObject.qty_40mt * 100) / 100;
                timeObject.qty_32mt = Math.round(timeObject.qty_32mt * 100) / 100;;
                timeObject.qty_27mt = Math.round(timeObject.qty_27mt * 100) / 100;;
                timeObject.item_quantity_others = Math.round(timeObject.item_quantity_others * 100) / 100;;

                return obj;
            };

            if (Array.isArray(data)) {
                data.forEach(item => {
                    const { transporter_zone = '', month = defaultMonthData, year = defaultYearData, yesterday = defaultYesterdayData } = item;

                    const sanitizedMonth = sanitizeObject(month, totalDefaultMonthData);
                    const sanitizedYear = sanitizeObject(year, totalDefaultYearData);
                    const sanitizedYesterday = sanitizeObject(yesterday, totalDefaultYesterdayData);

                    return {
                        transporter_zone,
                        month: sanitizedMonth,
                        year: sanitizedYear,
                        yesterday: sanitizedYesterday
                    };
                });

                // Push total data if there are more than one data items
                if (data.length > 1) {
                    data.push({
                        transporter_zone: 'Total',
                        month: { ...totalDefaultMonthData },
                        year: { ...totalDefaultYearData },
                        yesterday: { ...totalDefaultYesterdayData }
                    });
                }

                return data;
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error; // Re-throw the error to be caught in fetchTruckLoadSummary
        }
    }

    onShowZoneList = async () => {
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        });
        var response = await redirectURL.post("/consignments/getZonesList");
        var transporter_zone_arr = [{ 'label': "All", 'value': "All" }];
        response.data.forEach(item => {
            // Bug Fixes for 5811
            if (item) {
                transporter_zone_arr.push({ label: item, value: item });
            }
        });
        var truckLoadZone = this.state.truck_load_zone;
        this.setState({ transporter_zones: transporter_zone_arr, truck_load_zone: truckLoadZone }, async () => {
            // Trigger asynchronous functions after updating the state
            try {
                await Promise.all([
                    this.fetchTruckLoadSummary(truckLoadZone),
                    this.fetchZoneWiseDispatch(truckLoadZone.value),
                    this.loadTimeDaysData(truckLoadZone.value),
                    this.loadStackInTransit(truckLoadZone.value),
                    this.loadModeChange(truckLoadZone.value)
                ]);
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
                // Handle error accordingly
            }
        });
    }



    onClickFilterData = async () => {
        var truck_load_transporter = this.state.truck_load_zone
        await this.fetchTruckLoadSummary(truck_load_transporter);
        await this.fetchZoneWiseDispatch(truck_load_transporter.value);

        await this.loadTimeDaysData(truck_load_transporter.value);
        await this.loadStackInTransit(truck_load_transporter.value);
        await this.loadModeChange(truck_load_transporter.value)

    }
    onChangeZone = (e) => {
        this.setState({ truck_load_zone: e })
    }
    fetchZoneWiseDispatch = async (truckLoadZone) => {
        try {

            let todayData = await this.fetchDataForZoneDates(moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month'), truckLoadZone);
            // console.log(truckLoadZone, 'truckLoadZone')

            if (!Array.isArray(todayData)) {
                todayData = []
            }


            const yesterdayStart = moment().subtract(1, 'days').startOf('day'); // Set to yesterday 00:00:00
            const yesterdayEnd = moment().subtract(1, 'days').endOf('day'); // Set to yesterday 23:59:59

            let yesterdayData = await this.fetchDataForZoneDates(yesterdayStart, yesterdayEnd, truckLoadZone);
            if (!Array.isArray(yesterdayData)) {

                yesterdayData = []
            }

            this.setState({

                zone_wise_data: todayData,
                yesterday_zone_wise_data: yesterdayData || [],
            });
        } catch (error) {
            console.error("Error fetching data:", error);
            // Optionally, you can set state here to handle error states
        }
    }
    // fetchDataForZoneDates = async (startDate, endDate, truck_load_zone) => {
    //     try {
    //         const start_date = startDate.format('YYYY-MM-DD HH:mm:ss');
    //         const end_date = endDate.format('YYYY-MM-DD HH:mm:ss');

    //         const parameters = { start_date, end_date, };
    //         if (truck_load_zone != "All") {
    //             parameters.truck_load_zone = truck_load_zone
    //         }


    //         const response = await redirectURL.post("/consignments/zonewiseDispatchReport", parameters);
    //         const data = response.data || [];
    //         // console.log(data,'zonewisedispatchreport104')
    //         transporter_zone = 0
    //         road_item_quantity = 0
    //         rail_item_quantity = 0
    //         total_quantity = 0
    //         road_truck_no = 0
    //         rail_truck_no = 0
    //         const defaultRow = {
    //             transporter_zone: 'Total',
    //             road_item_quantity : 0 ,
    //             rail_item_quantity : 0 ,
    //             total_quantity : 0 ,
    //             road_truck_no : 0 ,
    //             rail_truck_no : 0 ,
    //         }
    //         if (Array.isArray(data)) {
    //             return data.map(item => ({
    //                 defaultRow.road_item_quantity += item.road_item_quantity,
    //                 ...item,
    //                 total_quantity: Math.round((item.rail_item_quantity || 0) + (item.road_item_quantity || 0))
    //             }));
    //         }

    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //         throw error; // Re-throw the error to be caught in fetchZoneWiseDispatch
    //     }
    // }
    fetchDataForZoneDates = async (startDate, endDate, truck_load_zone) => {
        try {
            const start_date = startDate.format('YYYY-MM-DD HH:mm:ss');
            const end_date = endDate.format('YYYY-MM-DD HH:mm:ss');

            const parameters = { start_date, end_date };
            if (truck_load_zone !== "All") {
                parameters.truck_load_zone = truck_load_zone;
            }
            if (localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "undefined") {
                parameters.plant_code = encode(localStorage.getItem("pc"));
            }

            const response = await redirectURL.post("/consignments/zonewiseDispatchReport", parameters);
            let data = response.data || [];

            // Initialize total counts
            let totalRoadItemQuantity = 0;
            let totalRailItemQuantity = 0;
            let totalRoadTruckNo = 0;
            let totalRailTruckNo = 0;

            if (Array.isArray(data)) {
                data.forEach(item => {
                    // Sum up quantities and truck numbers
                    totalRoadItemQuantity += item.road_item_quantity || 0;
                    totalRailItemQuantity += item.rail_item_quantity || 0;
                    totalRoadTruckNo += item.road_truck_no || 0;
                    totalRailTruckNo += item.rail_truck_no || 0;

                    // Calculate total quantity for each item
                    item.total_quantity = (item.rail_item_quantity || 0) + (item.road_item_quantity || 0);
                });

                // Append total counts as a separate item
                const totalRow = {
                    transporter_zone: 'Total',
                    road_item_quantity: Math.round(totalRoadItemQuantity * 100) / 100,
                    rail_item_quantity: Math.round(totalRailItemQuantity * 100) / 100,
                    total_quantity: Math.round(totalRoadItemQuantity + totalRailItemQuantity * 100) / 100,
                    road_truck_no: Math.round(totalRoadTruckNo * 100) / 100,
                    rail_truck_no: Math.round(totalRailTruckNo * 100) / 100,
                };
                if (data.length > 1) {
                    data.push(totalRow);
                }

            }

            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            throw error; // Re-throw the error to be caught in fetchZoneWiseDispatch
        }
    }



    loadTimeDaysData = async (transporter_zone) => {

        var transporter_zone = transporter_zone != "All" ? transporter_zone : ''

        try {
            const edate = moment().format('YYYY-MM-DD HH:mm:ss');
            const sdate = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');

            const parameters = [
                { shipment_mode: "ROAD", transporter_zone: transporter_zone },
                { shipment_mode: "RAIL", transporter_zone: transporter_zone },

            ];
            if (localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "undefined") {
                parameters.forEach(item => {
                    item.plant_code = encode(localStorage.getItem("pc"));
                })

            }

            const fetchData = async (params) => {
                try {
                    const response = await redirectURL.post("/consignments/truckTimeInDays", {
                        from_date: sdate,
                        to_date: edate,
                        ...params
                    });
                    var data_response = Array.isArray(response.data) ? response.data : []
                    const totalRoad = {
                        transporter_zone: "Total",
                        year: 0,
                        last_month: 0,
                        current_month: 0
                    };

                    const roadLeadData = data_response.map(item => {
                        const { year, last_month, current_month } = item;
                        totalRoad.year += year;
                        totalRoad.last_month += last_month;
                        totalRoad.current_month += current_month;
                        return { ...item };
                    });
                    totalRoad.year = Math.round(totalRoad.year * 100) / 100;
                    totalRoad.last_month = Math.round(totalRoad.last_month * 100) / 100;
                    totalRoad.current_month = Math.round(totalRoad.current_month * 100) / 100;
                    if (roadLeadData.length > 1) {
                        roadLeadData.push({ ...totalRoad });
                    }
                    return roadLeadData;
                } catch (error) {
                    console.error("Error fetching data:", error);
                    return [];
                }
            };

            const [roadData, railData] = await Promise.all(parameters.map(fetchData));

            this.setState({
                road_data_arr: roadData,
                rail_data_arr: railData,
            });

        } catch (error) {
            console.error("Error:", error);
        }
    };

    loadStackInTransit = async (transporter_zone) => {

        try {
            const stackStartDate = moment().subtract(1, 'month').startOf('month');
            const startDate = stackStartDate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
            const endDate = moment().subtract(1, 'month').endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss');
            const params = {
                from_date: startDate,
                to_date: endDate,
            }
            if (transporter_zone != 'All') {
                params.transporter_zone = transporter_zone
            }
            if (localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "undefined") {
                params.plant_code = encode(localStorage.getItem("pc"));
            }
            const response = await redirectURL.post("/consignments/stackInTransit", params);
            var stack_intrasit_data_arr = response.data || []
            if (!Array.isArray(stack_intrasit_data_arr)) {
                stack_intrasit_data_arr = []
            }
            const total_stock_intrasit_row = {
                transporter_zone: 'Total',
                item_quantity_road: 0,
                item_quantity_rail_load_port: 0,
                item_quantity_rail: 0,
                item_quantity_rail_dis_port: 0,
                item_quantity_last_lag: 0,
                total_quantity: 0,
                truck_no_road: 0,
            }
            stack_intrasit_data_arr = stack_intrasit_data_arr.map(item => {
                const {
                    item_quantity_road = 0,
                    item_quantity_rail_load_port = 0,
                    item_quantity_rail = 0,
                    item_quantity_rail_dis_port = 0,
                    item_quantity_last_lag = 0,
                    truck_no_road = 0
                } = item
                const total_quantity = item_quantity_road + item_quantity_rail_load_port + item_quantity_rail + item_quantity_rail_dis_port + item_quantity_last_lag
                total_stock_intrasit_row.item_quantity_road += item_quantity_road
                total_stock_intrasit_row.item_quantity_rail_load_port += item_quantity_rail_load_port
                total_stock_intrasit_row.item_quantity_rail += item_quantity_rail
                total_stock_intrasit_row.item_quantity_rail_dis_port += item_quantity_rail_dis_port
                total_stock_intrasit_row.item_quantity_last_lag += item_quantity_last_lag
                total_stock_intrasit_row.total_quantity += total_quantity
                total_stock_intrasit_row.truck_no_road += truck_no_road
                return {
                    ...item,
                    truck_no_road,
                    item_quantity_road,
                    item_quantity_rail_load_port,
                    item_quantity_rail,
                    item_quantity_rail_dis_port,
                    item_quantity_last_lag,
                    total_quantity
                }
            })
            if (stack_intrasit_data_arr.length > 1) {
                stack_intrasit_data_arr.push(total_stock_intrasit_row)
            }

            console.log('responseStackIn', response)

            this.setState({
                stack_intrasit_data: stack_intrasit_data_arr
            });


        } catch (error) {
            console.error("Error:", error);
        }
    };

    loadModeChange = async (transporter_zone) => {


        try {
            const modeStartDate = moment().subtract(1, 'month').startOf('month');
            const startDate = modeStartDate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
            const endDate = moment().subtract(1, 'month').endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss');
            const params = {
                from_date: startDate,
                to_date: endDate,

            }
            if (transporter_zone != "All") {
                params.transporter_zone = transporter_zone
            }
            if (localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "undefined") {
                params.plant_code = encode(localStorage.getItem("pc"));
            }
            const response = await redirectURL.post("/consignments/modeChangeReport", params);
            var mode_change_data_arr = response.data.total_data || []
            if (!Array.isArray(mode_change_data_arr)) {
                mode_change_data_arr = []
            }
            // console.log(response,'response380')
            const total_row_mode = {
                transporter_zone: "Total",
                rail_road: 0,
                road_rail: 0,
                sea_rail: 0,
                sea_road: 0,
                yest_rail_road: 0,
                yest_road_rail: 0,
                yest_sea_rail: 0,
                yest_sea_road: 0,
                total_quantity: 0,
                total_yest_quantity: 0,
            }

            var formatted_mode_change_data = mode_change_data_arr.map(item => {
                const {
                    transporter_zone = '',
                    rail_road = 0,
                    road_rail = 0,
                    sea_rail = 0,
                    sea_road = 0,
                    yest_rail_road = 0,
                    yest_road_rail = 0,
                    yest_sea_rail = 0,
                    yest_sea_road = 0,
                } = item
                const total_quantity = rail_road + road_rail + sea_rail + sea_road
                const total_yest_quantity = yest_rail_road + yest_road_rail + yest_sea_rail + yest_sea_road

                total_row_mode.rail_road += rail_road
                total_row_mode.road_rail += road_rail
                total_row_mode.sea_rail += sea_rail
                total_row_mode.sea_road += sea_road
                total_row_mode.yest_rail_road += yest_rail_road
                total_row_mode.yest_road_rail += yest_road_rail
                total_row_mode.yest_sea_rail += yest_sea_rail
                total_row_mode.yest_sea_road += yest_sea_road
                total_row_mode.total_quantity += total_quantity
                total_row_mode.total_yest_quantity += total_yest_quantity
                return {
                    transporter_zone,
                    rail_road,
                    road_rail,
                    sea_rail,
                    sea_road,
                    yest_rail_road,
                    yest_road_rail,
                    yest_sea_rail,
                    yest_sea_road,
                    total_quantity,
                    total_yest_quantity
                }
            })
            console.log('responseStackIn', formatted_mode_change_data)
            if (formatted_mode_change_data.length > 1) {
                formatted_mode_change_data.push(total_row_mode)
            }
            this.setState({

                mode_change_data: formatted_mode_change_data
            });


        } catch (error) {
            console.error("Error:", error);
        }
    };

    render() {
        const truck_load_data_arr = this.state.truck_load_data
        const tableHeaderStyle = {
            border: '1px solid #000',
            background: 'rgb(36 120 159)',
            color: 'white',
            textAlign: 'center',
            padding: '8px'
        };

        const tableCellStyle = {
            border: '1px solid #000',
            padding: '6px',
            textAlign: 'center',
            textTransform: 'uppercase',
            width: '120px'
        };
        // console.log(window.location.pathname,"pathname603")
        const pcCondition = !localStorage.getItem("pc") ||
            localStorage.getItem("pc").toLocaleLowerCase() === "all" ||
            localStorage.getItem("pc") === "HZ";

        const columns = [
            { name: "Zone", show: true },
            { name: "Road", show: true },
            { name: "Rail", show: pcCondition },
            { name: "Total", show: pcCondition },
            { name: "Road", show: true },
            { name: "Rail", show: pcCondition },
        ];
        const zoneWiseColspans = {
            mainHeader: pcCondition ? 6 : 3,
            subHdrQt: pcCondition ? 3 : 1,
            subHdrNoVeh: pcCondition ? 2 : 1
        }
        const stockIntransitColSpans = {
            mainHeader: pcCondition ? 7 : 2,
            subHdrQtL: pcCondition ? 7 : 2,
        }
        const columnsData = [
            { key: "transporter_zone", show: true },
            { key: "road_item_quantity", show: true },
            { key: "rail_item_quantity", show: pcCondition },
            { key: "total_quantity", show: pcCondition },
            { key: "road_truck_no", show: true },
            { key: "rail_truck_no", show: pcCondition },
        ];
        const stoIntransitColumnsData = [
            { name: "Zone", show: true },
            { name: "Road", show: true },
            { name: "Rail Loading Port", show: pcCondition },
            { name: "In-Transit Rail", show: pcCondition },
            { name: "Rail Dispatch Port", show: pcCondition },
            { name: "Last Mile", show: pcCondition },
            { name: "Total", show: pcCondition },
            { name: "Road", show: true },
        ]
        const stockIntransitKeys = [
            { key: "item_quantity_road", show: true },
            { key: "item_quantity_rail_load_port", show: pcCondition },
            { key: "item_quantity_rail", show: pcCondition },
            { key: "item_quantity_rail_dis_port", show: pcCondition },
            { key: "item_quantity_last_lag", show: pcCondition },
            { key: "total_quantity", show: pcCondition },
        ]
        return (
            <>
                <div className="card">
                    <div className="card-header">
                        <h5>
                            <i class="icofont icofont-vehicle-delivery-van cus-i"></i>
                            {" Zonewise Dispatch Report"}
                        </h5>
                    </div>
                    <div className='card-body'>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className={" col-xl-2 col-lg-2 form-group"}>
                                <label>TRANSPORTER ZONE</label>
                                <Select
                                    placeholder={"Select Transporter Zone"}
                                    closeMenuOnSelect={true}
                                    isMulti={false}
                                    value={this.state.truck_load_zone}
                                    onChange={this.onChangeZone}
                                    className={"border-radius-0"}
                                    style={{ borderRadius: "0px" }}
                                    options={this.state.transporter_zones} />
                            </div>
                            <div class="col-xl-2 col-lg-2 form-group">
                                <button type="submit" onClick={this.onClickFilterData} class="btn btn-success mt-30p">Get Data</button>&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        </div>
                        <div style={{ width: '100%', paddingTop: '10px', paddingRight: '12px' }}>
                            <div className="card-header" style={{ textAlign: 'center' }}>
                                <h5>
                                    TRUCK LOAD
                                </h5>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ width: '100%', marginRight: '14px' }}>
                                    <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px', marginRight: '14px', height: 'auto' }}>
                                        <thead>
                                            <tr>
                                                <th colSpan={13} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Average Qty Per Truck (in Tons)</th>
                                            </tr>
                                            <tr>
                                                <th colSpan={1} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}></th>
                                                <th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Year ({moment().format('YYYY')})</th>

                                                <th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Month  ({moment().subtract(1, 'month').format('YYYY-MMM')})</th>
                                                <th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Yesterday</th>
                                            </tr>
                                            <tr>
                                                <th style={tableHeaderStyle}>Zone</th>
                                                <th style={tableHeaderStyle}>40 MT</th>
                                                <th style={tableHeaderStyle}>32 MT</th>
                                                <th style={tableHeaderStyle}>27 MT</th>
                                                <th style={tableHeaderStyle}>Other</th>
                                                <th style={tableHeaderStyle}>40 MT</th>
                                                <th style={tableHeaderStyle}>32 MT</th>
                                                <th style={tableHeaderStyle}>27 MT</th>
                                                <th style={tableHeaderStyle}>Other</th>
                                                <th style={tableHeaderStyle}>40 MT</th>
                                                <th style={tableHeaderStyle}>32 MT</th>
                                                <th style={tableHeaderStyle}>27 MT</th>
                                                <th style={tableHeaderStyle}>Other</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {truck_load_data_arr.map((row, index) => (
                                                <tr key={index}>
                                                    <td style={tableCellStyle}>{(row.transporter_zone)}</td>
                                                    <td style={tableCellStyle}>{row.year.qty_40mt}</td>
                                                    <td style={tableCellStyle}>{row.year.qty_32mt}</td>
                                                    <td style={tableCellStyle}>{(row.year.qty_27mt)}</td>
                                                    <td style={tableCellStyle}>{row.year.item_quantity_others}</td>
                                                    <td style={tableCellStyle}>{row.month.qty_40mt}</td>
                                                    <td style={tableCellStyle}>{row.month.qty_32mt}</td>
                                                    <td style={tableCellStyle}>{(row.month.qty_27mt)}</td>
                                                    <td style={tableCellStyle}>{row.month.item_quantity_others}</td>
                                                    <td style={tableCellStyle}>{row.yesterday.qty_40mt}</td>
                                                    <td style={tableCellStyle}>{row.yesterday.qty_32mt}</td>
                                                    <td style={tableCellStyle}>{(row.yesterday.qty_27mt)}</td>
                                                    <td style={tableCellStyle}>{row.yesterday.item_quantity_others}</td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                    {this.state.truck_load_data.length == 0 &&
                                        <div style={{ textAlign: 'center', width: '100%', }}> No Data Found </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '100%', paddingTop: '10px', paddingRight: '12px' }}>
                            <div className="card-header" style={{ textAlign: 'center' }}>
                                <h5>
                                    ZONE WISE DISPATCH
                                </h5>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ width: '50%', marginRight: '14px' }}>
                                    <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px', marginRight: '14px', height: 'auto' }}>
                                        <thead>
                                            <tr>
                                                <th colSpan={zoneWiseColspans.mainHeader} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Month ({moment().subtract(1, 'month').format('YYYY-MMMM')})</th>
                                            </tr>
                                            <tr>
                                                <th colSpan={1} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}></th>
                                                <th colSpan={zoneWiseColspans.subHdrQt} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Quantity (in Tons)</th>
                                                <th colSpan={zoneWiseColspans.subHdrNoVeh} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>No of Vehicles</th>
                                            </tr>
                                            <tr>
                                                {columns.map((column, index) =>
                                                    column.show && (
                                                        <th key={index} style={tableHeaderStyle}>
                                                            {column.name}
                                                        </th>
                                                    )
                                                )}
                                                {/* <th style={tableHeaderStyle}>Zone</th>
                                                <th style={tableHeaderStyle}>Road</th>
                                                <th style={tableHeaderStyle}>Rail</th>
                                                <th style={tableHeaderStyle}>Total</th>
                                                <th style={tableHeaderStyle}>Road</th>
                                                <th style={tableHeaderStyle}>Rail</th> */}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.zone_wise_data.map((row, index) => (
                                                <tr key={index}>
                                                    {/*  Render table rows dynamically */}
                                                    {columnsData.map((column, colIndex) =>
                                                        column.show && (
                                                            <td key={colIndex} style={tableCellStyle}>
                                                                {row[column.key]}
                                                            </td>
                                                        )
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                    {this.state.zone_wise_data.length == 0 &&
                                        <div style={{ textAlign: 'center', width: '100%', }}> No Data Found </div>
                                    }
                                </div>
                                <div style={{ width: '50%', marginRight: '14px' }}>
                                    <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px', marginRight: '14px', height: 'auto' }}>
                                        <thead>
                                            <tr>
                                                <th colSpan={zoneWiseColspans.mainHeader} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Yesterday</th>
                                            </tr>
                                            <tr>
                                                <th colSpan={1} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}></th>
                                                <th colSpan={zoneWiseColspans.subHdrQt} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Quantity (in Tons)</th>
                                                <th colSpan={zoneWiseColspans.subHdrNoVeh} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>No of Vehicles</th>
                                            </tr>
                                            <tr>
                                                {columns.map((column, index) =>
                                                    column.show && (
                                                        <th key={index} style={tableHeaderStyle}>
                                                            {column.name}
                                                        </th>
                                                    )
                                                )}
                                                {/* <th style={tableHeaderStyle}>Zone</th>
                                                <th style={tableHeaderStyle}>Road</th>
                                                <th style={tableHeaderStyle}>Rail</th>
                                                <th style={tableHeaderStyle}>Total</th>
                                                <th style={tableHeaderStyle}>Road</th>
                                                <th style={tableHeaderStyle}>Rail</th> */}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.yesterday_zone_wise_data.map((row, index) => (
                                                <tr key={index}>
                                                    {columnsData.map((column, colIndex) =>
                                                        column.show && (
                                                            <td key={colIndex} style={tableCellStyle}>
                                                                {row[column.key]}
                                                            </td>
                                                        )
                                                    )}
                                                    {/* <td style={tableCellStyle}>{(row.transporter_zone)}</td>
                                                    <td style={tableCellStyle}>{row.road_item_quantity}</td>
                                                    <td style={tableCellStyle}>{row.rail_item_quantity}</td>
                                                    <td style={tableCellStyle}>{(row.total_quantity)}</td>
                                                    <td style={tableCellStyle}>{row.road_truck_no}</td>
                                                    <td style={tableCellStyle}>{row.rail_truck_no}</td> */}
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                    {this.state.yesterday_zone_wise_data.length == 0 &&
                                        <div style={{ textAlign: 'center', width: '100%', }}> No Data Found </div>
                                    }
                                </div>
                            </div>
                            <div style={{ width: '100%', }}>
                                <div style={{ width: '100%', paddingTop: '10px', paddingRight: '12px' }}>
                                    <div className="card-header" style={{ textAlign: 'center' }}>
                                        <h5>
                                            LEAD TIME IN DAYS
                                        </h5>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '100%'}}>
                                            <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px', marginRight: '14px', height: 'auto' }}>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Road Days</th>
                                                    </tr>

                                                    <tr>
                                                        <th style={tableHeaderStyle}>Zone</th>
                                                        <th style={tableHeaderStyle}>Year ({moment().format('YYYY')})</th>
                                                        <th style={tableHeaderStyle}>Pre Month</th>
                                                        <th style={tableHeaderStyle}>Current Month</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.road_data_arr.map((row, index) => (
                                                        <tr key={index}>
                                                            <td style={tableCellStyle}>{(row.transporter_zone)}</td>
                                                            <td style={tableCellStyle}>{Math.round(row.year * 100) / 100}</td>
                                                            <td style={tableCellStyle}>{Math.round(row.last_month * 100) / 100}</td>
                                                            <td style={tableCellStyle}>{Math.round(row.current_month * 100) / 100}</td>


                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                            {this.state.road_data_arr.length == 0 &&
                                                <div style={{ textAlign: 'center', width: '100%', }}> No Data Found </div>
                                            }
                                        </div>
                                        {/* // Changes to Plantwise Data - bug id - 5391 */}
                                        {(!localStorage.getItem("pc") || localStorage.getItem("pc").toLocaleLowerCase() == "all" || localStorage.getItem("pc") == "HZ") &&
                                            <div style={{ width: '100%', marginLeft: '14px' }}>
                                                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px', marginRight: '12px', height: 'auto' }}>
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Rail Days</th>
                                                        </tr>

                                                        <tr>
                                                            <th style={tableHeaderStyle}>Zone</th>
                                                            <th style={tableHeaderStyle}>Year ({moment().format('YYYY')})</th>
                                                            <th style={tableHeaderStyle}>Pre Month</th>
                                                            <th style={tableHeaderStyle}>Current Month</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.rail_data_arr.map((row, index) => (
                                                            <tr key={index}>
                                                                <td style={tableCellStyle}>{(row.transporter_zone)}</td>
                                                                <td style={tableCellStyle}>{Math.round(row.year * 100) / 100}</td>
                                                                <td style={tableCellStyle}>{Math.round(row.last_month * 100) / 100}</td>
                                                                <td style={tableCellStyle}>{Math.round(row.current_month * 100) / 100}</td>

                                                            </tr>
                                                        ))}
                                                    </tbody>

                                                </table>
                                                {this.state.rail_data_arr.length == 0 &&
                                                    <div style={{ textAlign: 'center', width: '100%', }}> No Data Found </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div style={{ width: '100%', paddingTop: '10px', paddingRight: '12px'}}>
                                    <div className="card-header" style={{ textAlign: 'center' }}>
                                        <h5>
                                            STOCK IN TRANSIT
                                        </h5>
                                    </div>
                                    <div style={{ width: '100%', }}>
                                        <div style={{ width: '100%', }}>
                                            <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px', height: 'auto' }}>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={stockIntransitColSpans.mainHeader} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Quantity (in Tons)</th>
                                                        <th colSpan={1} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>No's</th>
                                                    </tr>
                                                    <tr>
                                                        {stoIntransitColumnsData.map((column, index) =>
                                                            column.show && (
                                                                <th style={tableHeaderStyle} key={index}>
                                                                    {column.name}
                                                                </th>
                                                            )
                                                        )}
                                                        {/* <th style={tableHeaderStyle}>Zone</th>
                                                            <th style={tableHeaderStyle}>Road</th>
                                                            <th style={tableHeaderStyle}>Rail Loading Port</th>
                                                            <th style={tableHeaderStyle}>In-Transit Rail</th>
                                                            <th style={tableHeaderStyle}>Rail Dispatch Port</th>
                                                            <th style={tableHeaderStyle}>Last Mile</th>
                                                            <th style={tableHeaderStyle}>Total</th>
                                                            <th style={tableHeaderStyle}>Road</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.stack_intrasit_data.map((row, index) => (
                                                        <tr key={index}>
                                                            <td style={tableCellStyle}>{(row.transporter_zone)}</td>
                                                            {/* Dynamic columns based on stockIntransitKeys */}
                                                            {stockIntransitKeys.map((column, colIndex) =>
                                                                column.show && (
                                                                    <td style={tableCellStyle} key={colIndex}>
                                                                        {Math.round(row[column.key] * 100) / 100}
                                                                    </td>
                                                                )
                                                            )}
                                                            {/* <td style={tableCellStyle}>{Math.round(row.item_quantity_road * 100) / 100}</td>
                                                                <td style={tableCellStyle}>{Math.round(row.item_quantity_rail_load_port * 100) / 100}</td>
                                                                <td style={tableCellStyle}>{Math.round(row.item_quantity_rail * 100) / 100}</td>
                                                                <td style={tableCellStyle}>{Math.round(row.item_quantity_rail_dis_port * 100) / 100}</td>
                                                                <td style={tableCellStyle}>{Math.round(row.item_quantity_last_lag * 100) / 100}</td>
                                                                <td style={tableCellStyle}>{Math.round(row.total_quantity * 100) / 100}</td> */}
                                                            <td style={tableCellStyle}>{row.truck_no_road}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                            {this.state.stack_intrasit_data.length == 0 &&
                                                <div style={{ textAlign: 'center', width: '100%', }}> No Data Found </div>
                                            }
                                        </div>

                                    </div>

                                </div>
                                {/* // Changes to Plantwise Data - bug id - 5391 */}
                                {(!localStorage.getItem("pc") || localStorage.getItem("pc").toLocaleLowerCase() == "all" || localStorage.getItem("pc") == "HZ") &&
                                    <div style={{ width: '100%', paddingTop: '10px', paddingRight: '12px', marginBottom: '18px' }}>
                                        <div className="card-header" style={{ textAlign: 'center' }}>
                                            <h5>
                                                MODE CHANGE
                                            </h5>
                                        </div>
                                        <div style={{ width: '100%', }}>
                                            <div style={{ width: '100%', }}>
                                                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px', height: 'auto' }}>
                                                    <thead>

                                                        <tr>
                                                            <th colSpan={1} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}></th>

                                                            <th colSpan={5} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Current Month Qty (in Tons)</th>
                                                            <th colSpan={5} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Yesterday Qty (in Tons)</th>

                                                        </tr>

                                                        <tr>
                                                            <th style={tableHeaderStyle}>Zone</th>
                                                            <th style={tableHeaderStyle}>Road To Rail </th>
                                                            <th style={tableHeaderStyle}>Rail To Road</th>
                                                            <th style={tableHeaderStyle}>Sea To Road</th>
                                                            <th style={tableHeaderStyle}>Sea To Rail</th>
                                                            <th style={tableHeaderStyle}>Total</th>
                                                            <th style={tableHeaderStyle}>Road To Rail </th>
                                                            <th style={tableHeaderStyle}>Rail To Road</th>
                                                            <th style={tableHeaderStyle}>Sea To Road</th>
                                                            <th style={tableHeaderStyle}>Sea To Rail</th>
                                                            <th style={tableHeaderStyle}>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.mode_change_data.map((row, index) => (
                                                            <tr key={index}>
                                                                <td style={tableCellStyle}>{(row.transporter_zone)}</td>
                                                                <td style={tableCellStyle}>{Math.round(row.rail_road * 100) / 100}</td>
                                                                <td style={tableCellStyle}>{Math.round(row.road_rail * 100) / 100}</td>
                                                                <td style={tableCellStyle}>{Math.round(row.sea_rail * 100) / 100}</td>
                                                                <td style={tableCellStyle}>{Math.round(row.sea_road * 100) / 100}</td>
                                                                <td style={tableCellStyle}>{Math.round(row.total_quantity * 100) / 100}</td>
                                                                <td style={tableCellStyle}>{Math.round(row.yest_rail_road * 100) / 100}</td>
                                                                <td style={tableCellStyle}>{Math.round(row.yest_road_rail * 100) / 100}</td>
                                                                <td style={tableCellStyle}>{Math.round(row.yest_sea_rail * 100) / 100}</td>
                                                                <td style={tableCellStyle}>{Math.round(row.yest_sea_road * 100) / 100}</td>
                                                                <td style={tableCellStyle}>{Math.round(row.total_yest_quantity * 100) / 100}</td>

                                                            </tr>
                                                        ))}
                                                    </tbody>

                                                </table>
                                                {this.state.mode_change_data.length == 0 &&
                                                    <div style={{ textAlign: 'center', width: '100%', }}> No Data Found </div>
                                                }
                                            </div>

                                        </div>

                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </>
        );
    }
}

export default TruckLoadSummary;